import {Form, notification} from 'antd'
import {useState, Fragment} from 'react'
import {sendCode} from '../../auth/auth'
import Spinner from '../../Common/Spinner/Spinner'
import LoginButton from '../LoginButton/LoginButton'
import LoginInput from '../LoginInput/LoginInput'

import styles from './SendCodeModal.module.css'

function SendCodeFirstStep({onCancel, onSendCodeDone}) {
    const [sendCodeForm] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    const [isSendCodeDisabled, setIsSendCodeDisabled] = useState(true)

    function onSubmit(values) {
        setIsLoading(true)
        sendCode(values.forgotPasswordAddress.toLowerCase(), onSendCodeResponse)
    }

    function onSendCodeResponse(response) {
        setIsLoading(false)
        if (response === 'success') {
            goToResetPassword()
            notification.success({
                message: 'The code was sent to your email'
            })
        } else if (response === 'failure') {
            sendCodeForm.resetFields()
            notification.error({
                message: 'The code was not sent'
            })
        }
    }

    function onValuesChange(changedValues, allValues) {
        if (changedValues.hasOwnProperty('forgotPasswordAddress')) {
            setIsSendCodeDisabled(!changedValues.forgotPasswordAddress)
        }
    }

    function onCancelClick() {
        sendCodeForm.resetFields()
        setIsSendCodeDisabled(true)
        setIsLoading(false)
        onSendCodeDone(false)
        onCancel()
    }

    function goToResetPassword() {
        const forgotPasswordAddress = sendCodeForm
            .getFieldValue('forgotPasswordAddress')
            .toLowerCase()
        onSendCodeDone(true, forgotPasswordAddress)
    }

    return (
        <Fragment>
            <p>
                Please enter your email and click on "Send Code," or "Already Have Code" if you have
                received a code already:
            </p>
            {isLoading && <Spinner className={styles.spinner} />}
            <Form
                form={sendCodeForm}
                name="send-code-form"
                onFinish={onSubmit}
                onValuesChange={onValuesChange}
            >
                <Form.Item name="forgotPasswordAddress" className={styles.formItem}>
                    <LoginInput
                        name="forgotPasswordAddress"
                        type="text"
                        autoComplete="off"
                        placeholder="Your Login Email"
                    />
                </Form.Item>
                <LoginButton
                    size="large"
                    className={styles.sendCodeBtn}
                    type="primary"
                    value="send code"
                    htmlType="submit"
                    disabled={isSendCodeDisabled}
                    block
                >
                    Send Code
                </LoginButton>
            </Form>
            <LoginButton
                size="large"
                className={styles.sendCodeAuxBtn}
                type="primary"
                value="skip code send"
                htmlType="button"
                disabled={isSendCodeDisabled}
                onClick={goToResetPassword}
            >
                Already Have Code
            </LoginButton>
            <LoginButton
                size="large"
                className={styles.sendCodeAuxBtn}
                type="primary"
                value="cancel"
                htmlType="button"
                onClick={onCancelClick}
            >
                Cancel
            </LoginButton>
        </Fragment>
    )
}

export default SendCodeFirstStep
