import {useMutation} from '@apollo/client'
import {Col, Form, Input, notification, Row} from 'antd'
import {Fragment} from 'react/cjs/react.production.min'
import {UPDATE_PERSONAL_DETAILS} from '../../../graphQL/mutations/updatePersonalDetails'
import RoundButton from '../../Common/RoundButton/RoundButton'

import styles from './GeneralSettings.module.css'

function GeneralSettings({userDetails = {}, subscriberId}) {
    const [updatePersonalDetails, {loading: updatingPersonalDetails}] = useMutation(
        UPDATE_PERSONAL_DETAILS,
        {
            onCompleted: handleSaveComplete,
            onError: handleSaveError
        }
    )

    function handleSaveError() {
        notification.error({
            message: 'The update failed'
        })
    }

    function handleSaveComplete() {
        notification.success({
            message: 'The update was successful!'
        })
    }

    function handleSave(values) {
        updatePersonalDetails({
            variables: {
                subscriberId,
                params: values
            }
        })
    }
    return (
        <Fragment>
            <label className={styles.detailsTitle}>Personal Details</label>
            <Form
                className={styles.form}
                layout="vertical"
                initialValues={userDetails}
                onFinish={handleSave}
            >
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item label="First Name" name="firstName">
                            <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item label="Mobile Phone" name="phoneNumber">
                            <Input placeholder="Mobile Phone" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item label="Last Name" name="lastName">
                            <Input placeholder="Last Name" />
                        </Form.Item>
                        <Form.Item label="Alternate Phone" name="secondaryPhoneNumber">
                            <Input placeholder="Alternate Phone" />
                        </Form.Item>
                    </Col>
                </Row>
                <label className={styles.detailsTitle}>Address:</label>
                <span className={styles.details}>{userDetails.fullAddress}</span>
                <label className={styles.detailsTitle}>Account Number:</label>
                <span className={styles.details}>{userDetails.utilityAccountNumber}</span>
                <label className={styles.detailsTitle}>Service Number: </label>
                <span className={styles.details}>{userDetails.utilityServiceNumber}</span>
                <RoundButton
                    className={styles.submitButton}
                    htmlType="submit"
                    type="primary"
                    disabled={updatingPersonalDetails}
                >
                    Save Changes
                </RoundButton>
            </Form>
        </Fragment>
    )
}

export default GeneralSettings
