import {useState, useEffect, Fragment, React, useRef, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {useQuery} from '@apollo/client'
import {Form, Input, Button, Row, Col, Select, Progress} from 'antd'
import routes from '../../../routes'
import {
    callSubscriberGet,
    callUtilityAccountPost,
    callMfaCodePut,
    callUtilityAccountPut
} from '../../api/call-api'
import styles from './UtilityLinkForm.module.css'
import HeaderComponent from '../CommonComponents/HeaderComponent'
import ReviewsComponent from '../CommonComponents/ReviewsComponent'
import FooterComponent from '../CommonComponents/FooterComponent'
import SpotsRemainingComponent from '../CommonComponents/SpotsRemainingComponent'
import SpotsLeftComponent from '../CommonComponents/SpotsLeftComponent'
import {GET_UTILITY_BY_ZIPCODE} from '../../../graphQL/queries/utility'
import {pepcoImage} from '../../../assets'
import SignUpNowButton from '../CommonComponents/SignUpNowButton'
import AdvantagesComponent from '../CommonComponents/AdvantagesComponent'
import {
    googleTagUtilitylinkFormSuccess_Step_A,
    googleTagUtilitylinkFormFailed_Step_A,
    googleTagPepcoLoginSuccess_Step_B,
    googleTagPepcoLoginFailed_Step_B
} from '../../util/googleTagManager'
import UploadBillModal from '../UploadBillModal/UploadBillModal'

const {Option} = Select

const fieldsValidation = {
    zipcode: [
        {required: true, message: 'Please enter a Zipcode.'},
        {max: 5, message: 'Please enter a valid Zipcode'}
    ]
}

function PepcoBox({children, hiddePepcoLogo, connectToPepcoMessage, hideComments}) {
    return (
        <div className={styles.utilityLogin}>
            <div className={styles.loginHeader} align="center">
                <div style={{visibility: hiddePepcoLogo}} className={styles.pepcoImageBox}>
                    <img alt="Pepco Logo" src={pepcoImage} className={styles.pepcoImage} />
                </div>
                {!hideComments && (
                    <>
                        <span className={styles.connect_to_pepco}>{connectToPepcoMessage}</span>
                        <br />
                        <span className={styles.security_info}>We always keep your data safe</span>
                    </>
                )}
            </div>
            {children}
        </div>
    )
}

function UtilityLinkForm() {
    const history = useHistory()
    const [utilityLinkForm] = Form.useForm()
    const [loginForm] = Form.useForm()
    const [faForm] = Form.useForm()

    // const [utilityName, setUtilityName] = useState()
    const [errorMessages, setErrorMessages] = useState()
    const [errorMessagesPepco, setErrorMessagesPepco] = useState()
    const [errorMessages2fa, setErrorMessages2fa] = useState()
    const [successMessages, setSuccessMessages] = useState()
    const [uzipcode, setUzipcode] = useState('0')
    const [firstName, setFirstName] = useState()
    const [zipCode, setZipCode] = useState()
    const [utilityAccountId, setUtilityAccountId] = useState()
    const [delay, setDelay] = useState(1000)
    const [tokenSubs, setTokenSubs] = useState()
    const [time, setTime] = useState(0)
    const [running, setRunning] = useState(false)
    const [subscriberPolling, setSubscriberPolling] = useState(false)

    const [enablePepco, setEnablePepco] = useState(false)
    const [enable2fa, setEnable2fa] = useState(false)
    const [enableContinue, setEnableContinue] = useState(false)

    const [utilityUserName, setUtilityUserName] = useState()
    const [utilityUserPassword, setUtilityUserPassword] = useState()
    const [utilityUserMFaCode, setUtilityUserMFaCode] = useState()

    const [firstConnectClicked, setFirstConnectClicked] = useState(0)
    const [secondConnectClicked, setSecondConnectClicked] = useState(0)

    const [pepcoSignIn, setPepcoSignIn] = useState({status: 0, message: 'Signing in...'})

    const {data, refetch} = useQuery(GET_UTILITY_BY_ZIPCODE, {
        variables: {zipcode: uzipcode},
        fetchPolicy: 'network-only'
    })

    const resetStates = useCallback(() => {
        localStorage.clear()
        const queryParams = new URLSearchParams(window.location.search)
        const utmParameters = {
            utmCampaign: queryParams.get('utm_campaign'),
            utmSource: queryParams.get('utm_source'),
            utmMedium: queryParams.get('utm_medium'),
            timeClicked: new Date(),
            zipCode: queryParams.get('zip_code'),
            firstName: queryParams.get('first_name') || '',
            lastName: queryParams.get('last_name') || '',
            email: queryParams.get('email') || '',
            phoneNumber: queryParams.get('phone') || ''
        }
        localStorage.setItem('utmParameters', JSON.stringify(utmParameters))
    }, [])

    function handleSubmit(values) {
        if (values.utility.includes('Potomac Electric Power') || values.utility.includes('PEPCO')) {
            setErrorMessages('')
            setSuccessMessages('The ' + values.utility + ' account has been validated sucessfully.')
            // setUtilityName(values.utility)
            history.push(routes.onBoardingPayment)
        } else {
            setEnablePepco(false)
            setErrorMessages(
                'Unfortunately, we don’t have any solar facilities in your area yet.  We’re constructing new facilities all of the time.  We’ll let you know when one is active in your area. '
            )
        }
    }

    function handleSubmitPepco(values) {
        try {
            let token = localStorage.getItem('substkn')
            setTokenSubs(token)
            if (utilityAccountId) {
                let body = {
                    utilityAccountId: utilityAccountId,
                    username: values.username,
                    password: values.password
                }
                callUtilityAccountPut(
                    token,
                    body,
                    (res) => {
                        if (res.message === 'Scraper triggered') {
                            setEnableContinue(false)
                            setEnablePepco(false)
                            setEnable2fa(false)
                            setRunning(true)
                            setSubscriberPolling(true)
                        } else {
                            googleTagPepcoLoginFailed_Step_B()
                            setErrorMessages(
                                'An error has occured.  Please try again, or contact support@savesolar.us for assistance.'
                            )
                        }
                    },
                    (err) => {
                        googleTagPepcoLoginFailed_Step_B()
                        setErrorMessages(
                            'An error has occured.  Please try again, or contact support@savesolar.us for assistance.'
                        )
                    }
                )
            } else {
                let body = {
                    username: values.username,
                    password: values.password
                }
                callUtilityAccountPost(
                    token,
                    body,
                    (res) => {
                        if (res.message === 'Created') {
                            setEnableContinue(false)
                            setEnablePepco(false)
                            setEnable2fa(false)
                            setRunning(true)
                            setSubscriberPolling(true)
                        } else {
                            googleTagPepcoLoginFailed_Step_B()
                            setErrorMessages(
                                'An error has occured.  Please try again, or contact support@savesolar.us for assistance.'
                            )
                        }
                    },
                    (err) => {
                        googleTagPepcoLoginFailed_Step_B()
                        setErrorMessages(
                            'An error has occured.  Please try again, or contact support@savesolar.us for assistance.'
                        )
                    }
                )
            }
        } catch (error) {
            if (error.message === 'Token is not valid') {
                console.error(error)
                resetStates()
                history.push(routes.onBoardingSignup)
            } else {
                console.error(error)
            }
        }
    }

    function handleSubmit2Fa(values) {
        try {
            let body = {
                utilityAccountId: utilityAccountId,
                mfaCode: values.mfacode
            }
            callMfaCodePut(
                tokenSubs,
                body,
                (res) => {
                    if (res.message === 'Success') {
                        setEnable2fa(false)
                        setRunning(true)
                        setSubscriberPolling(true)
                    } else {
                        setEnable2fa(true)
                        setSubscriberPolling(false)
                        setRunning(false)
                        setErrorMessages(
                            'An error has occured.  Please try again, or contact support@savesolar.us for assistance.'
                        )
                    }
                },
                (err) => {
                    setErrorMessages(
                        'An error has occured.  Please try again, or contact support@savesolar.us for assistance.'
                    )
                }
            )
            setErrorMessages2fa('')
        } catch (error) {
            if (error.message === 'Token is not valid') {
                console.error(error)
                resetStates()
                history.push(routes.onBoardingSignup)
            } else {
                console.error(error)
            }
        }
    }

    function handleValuesChange(values) {
        if (values.utility) {
            if (
                values.utility.includes('Potomac Electric Power') ||
                values.utility.includes('PEPCO')
            ) {
                setEnableContinue(false)
                setErrorMessages('')
                // setUtilityName(values.utility)
                setEnablePepco(true)
                googleTagUtilitylinkFormSuccess_Step_A()
            } else {
                setEnablePepco(false)
                setErrorMessages(
                    'Unfortunately, we don’t have any solar facilities in your area yet.  We’re constructing new facilities all of the time.  We’ll let you know when one is active in your area. '
                )
                googleTagUtilitylinkFormFailed_Step_A()
            }
        } else {
            setEnablePepco(false)
            setErrorMessages('')
        }
    }

    function updateZipcodeState(value) {
        if (value && value.length === 5) {
            setUzipcode(value)
        }
    }

    function changeUtilityVerificationStatus(
        runningValue,
        enableContinueValue,
        enablePepcoValue,
        enable2faValue,
        subscriberPollingValue,
        errMsg,
        successMsg,
        pepcoErrMsg
    ) {
        setRunning(runningValue)
        setEnableContinue(enableContinueValue)
        setEnablePepco(enablePepcoValue)
        setEnable2fa(enable2faValue)
        setSubscriberPolling(subscriberPollingValue)
        setErrorMessages(errMsg)
        setErrorMessagesPepco(pepcoErrMsg)
        setSuccessMessages(successMsg)
    }

    useEffect(() => {
        setFirstName(localStorage.getItem('firstName'))
        try {
            callSubscriberGet(
                (res) => {
                    if (res && res.utilityAccounts[0] && res.utilityAccounts[0].utilityAccountId) {
                        setUtilityAccountId(res.utilityAccounts[0].utilityAccountId)
                    }
                },
                (err) => {
                    setRunning(false)
                    setErrorMessages(
                        'Error validating credentials please contact to support@savesolar.us.'
                    )
                }
            )
        } catch (error) {
            if (error.message === 'Token is not valid') {
                console.error(error)
                resetStates()
                history.push(routes.onBoardingSignup)
            } else {
                console.error(error)
            }
        }
    }, [firstName, history, resetStates])

    useEffect(() => {
        if (localStorage.getItem('zipCode') && localStorage.getItem('zipCode').length === 5) {
            setZipCode(localStorage.getItem('zipCode'))
            setUzipcode(localStorage.getItem('zipCode'))
            refetch({utilityZipcode: zipCode})
            utilityLinkForm.setFieldsValue({
                zipcode: zipCode
            })
        }
    }, [zipCode, refetch, utilityLinkForm])

    useEffect(() => {
        // call hook useQuery - refetch
        refetch({utilityZipcode: uzipcode})
    }, [uzipcode, refetch, data])

    useEffect(() => {
        let interval
        if (running) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 10)
                try {
                    callSubscriberGet(
                        (res) => {
                            if (res.status === 200) {
                                const checkUtilityAccountStatus = (
                                    utilityAccountStatus,
                                    subscriberStatus
                                ) =>
                                    !!(
                                        res &&
                                        res.subscriberStatus === subscriberStatus &&
                                        res.utilityAccounts[0] &&
                                        res.utilityAccounts[0].utilityAccountStatus ===
                                            utilityAccountStatus
                                    )

                                const utilityAccountStatus =
                                    res.utilityAccounts[0].utilityAccountStatus
                                if (
                                    utilityAccountStatus.includes('AWAITING CODE') ||
                                    utilityAccountStatus.includes('UTILITY VERIFICATION') ||
                                    utilityAccountStatus.includes('CODE RECEIVED')
                                ) {
                                    setPepcoSignIn({status: 1, message: 'Verifying your account..'})
                                } else if (utilityAccountStatus.includes('GETTING DATA')) {
                                    setPepcoSignIn({status: 2, message: 'Linking your account...'})
                                } else {
                                    setPepcoSignIn({status: 0, message: 'Signing in...'})
                                }

                                if (
                                    checkUtilityAccountStatus(
                                        'UTILITY ACCOUNT CREATED',
                                        'UTILITY VERIFICATION'
                                    )
                                ) {
                                    changeUtilityVerificationStatus(true, false, false, false, true)
                                } else if (
                                    checkUtilityAccountStatus(
                                        'AWAITING CODE',
                                        'UTILITY VERIFICATION'
                                    )
                                ) {
                                    setUtilityAccountId(res.utilityAccounts[0].utilityAccountId)
                                    changeUtilityVerificationStatus(
                                        false,
                                        false,
                                        false,
                                        true,
                                        false
                                    )
                                } else if (
                                    checkUtilityAccountStatus(
                                        'CREDENTIAL VALIDATION FAILED',
                                        'UTILITY VERIFICATION'
                                    )
                                ) {
                                    googleTagPepcoLoginFailed_Step_B()
                                    setUtilityAccountId(res.utilityAccounts[0].utilityAccountId)
                                    const errorMessagePepco =
                                        'There was a problem linking your account.  Re-enter your username and password to try again, or contact support@savesolar.us for help.'
                                    changeUtilityVerificationStatus(
                                        false,
                                        false,
                                        true,
                                        false,
                                        false,
                                        null,
                                        null,
                                        errorMessagePepco
                                    )
                                } else if (
                                    checkUtilityAccountStatus(
                                        'UPDATED CREDENTIALS',
                                        'UTILITY VERIFICATION'
                                    )
                                ) {
                                    setUtilityAccountId(res.utilityAccounts[0].utilityAccountId)
                                    changeUtilityVerificationStatus(true, false, false, false, true)
                                } else if (
                                    checkUtilityAccountStatus(
                                        'CODE RECEIVED',
                                        'UTILITY VERIFICATION'
                                    )
                                ) {
                                    changeUtilityVerificationStatus(true, false, false, false, true)
                                } else if (
                                    checkUtilityAccountStatus('FAILED', 'UTILITY VERIFICATION')
                                ) {
                                    googleTagPepcoLoginFailed_Step_B()
                                    const errMsg =
                                        'Error validating the account. Please contact to support@savesolar.us.'
                                    changeUtilityVerificationStatus(
                                        false,
                                        false,
                                        true,
                                        false,
                                        false,
                                        errMsg
                                    )
                                } else if (
                                    checkUtilityAccountStatus('MAINTENANCE', 'UTILITY VERIFICATION')
                                ) {
                                    googleTagPepcoLoginFailed_Step_B()
                                    const errorMessagePepco =
                                        "Pepco's customer portal is currently unavailable. Please try again later."
                                    changeUtilityVerificationStatus(
                                        false,
                                        false,
                                        true,
                                        false,
                                        false,
                                        null,
                                        null,
                                        errorMessagePepco
                                    )
                                } else if (
                                    checkUtilityAccountStatus('MAINTENANCE', 'UTILITY VERIFIED')
                                ) {
                                    googleTagPepcoLoginSuccess_Step_B()
                                    const successMsg =
                                        'The account has been validated sucessfully. Please click on CONTINUE'
                                    changeUtilityVerificationStatus(
                                        false,
                                        true,
                                        false,
                                        false,
                                        false,
                                        null,
                                        successMsg
                                    )
                                } else if (
                                    checkUtilityAccountStatus(
                                        'NO BILL PDF AVAILABLE',
                                        'UTILITY VERIFICATION'
                                    )
                                ) {
                                    googleTagPepcoLoginSuccess_Step_B()
                                    const successMsg = 'Pepco account successfully connected'
                                    // 'Your utility account was linked successfully.  Please click below to continue.'
                                    changeUtilityVerificationStatus(
                                        false,
                                        true,
                                        false,
                                        false,
                                        false,
                                        null,
                                        successMsg
                                    )
                                } else if (
                                    checkUtilityAccountStatus('FAILED', 'UTILITY VERIFIED')
                                ) {
                                    googleTagPepcoLoginSuccess_Step_B()
                                    const successMsg = 'Pepco account successfully connected'
                                    // 'Your utility account was linked successfully.  Please click below to continue.'
                                    changeUtilityVerificationStatus(
                                        false,
                                        true,
                                        false,
                                        false,
                                        false,
                                        null,
                                        successMsg
                                    )
                                } else if (
                                    checkUtilityAccountStatus('COMPLETE', 'UTILITY VERIFIED')
                                ) {
                                    googleTagPepcoLoginSuccess_Step_B()
                                    const successMsg = 'Pepco account successfully connected'
                                    // 'Your utility account was linked successfully.  Please click below to continue.'
                                    changeUtilityVerificationStatus(
                                        false,
                                        true,
                                        false,
                                        false,
                                        false,
                                        null,
                                        successMsg
                                    )
                                }
                            } else {
                                googleTagPepcoLoginFailed_Step_B()
                                setRunning(false)
                                setErrorMessages(
                                    'Error validating credentials please contact to support@savesolar.us.'
                                )
                            }
                        },
                        (err) => {
                            googleTagPepcoLoginFailed_Step_B()
                            setRunning(false)
                            setErrorMessages(
                                'Error validating credentials please contact to support@savesolar.us.'
                            )
                        }
                    )
                } catch (error) {
                    if (error.message === 'Token is not valid') {
                        console.error(error)
                        resetStates()
                        history.push(routes.onBoardingSignup)
                    } else {
                        console.error(error)
                    }
                }
            }, 1000)
        } else if (!running) {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [running, delay, setDelay, time, history, resetStates])

    const searchInput = useRef(null)

    function handleFocus() {
        searchInput.current.focus()
    }

    return (
        <>
            <div>
                <HeaderComponent />
            </div>
            <Row>
                <Col xs={24} xl={12} className={styles.frame}>
                    <>
                        <Row className={styles.utilityHeader}>
                            <Col xs={24} xl={24}>
                                <h2 className={styles.utilityUsername}>
                                    <div
                                        style={{color: '#18acd5'}}
                                        className={styles.only_show_on_desktop}
                                    >
                                        Nice to meet you, {firstName}!
                                    </div>
                                    <div className={styles.only_show_on_mobile}>
                                        Hi {firstName}!
                                    </div>
                                </h2>
                                <h3 className={styles.going_green_sentence}>
                                    <span className={styles.only_show_on_desktop}>
                                        Let's tell your utility{' '}
                                    </span>

                                    <span
                                        className={styles.only_show_on_desktop}
                                        style={{whiteSpace: 'nowrap'}}
                                    >
                                        you're going green
                                    </span>
                                    <span
                                        className={styles.only_show_on_mobile}
                                        style={{whiteSpace: 'nowrap'}}
                                    >
                                        You are on your way to go green
                                    </span>
                                </h3>
                                <div className={styles.small_blue_highlight} />
                            </Col>
                        </Row>
                        <Row className={styles.only_show_on_desktop}>
                            <Col xs={24} xl={24}>
                                The next step is informing your utility. We'll do that for you. To
                                get started, we'll need a few details from you.
                            </Col>
                        </Row>{' '}
                        <div className={styles.spots_left_component}>
                            <SpotsLeftComponent />
                        </div>
                        <Row>
                            <Col style={{marginBottom: '1.4rem'}} xs={24} xl={24}>
                                <span
                                    onClick={() => history.push(routes.onBoarding)}
                                    className={styles.backText}
                                >
                                    {'< Back'}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} xl={24}>
                                <Fragment>
                                    <div>
                                        <h3
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                marginBottom: '25px'
                                            }}
                                        >
                                            Link your utility account
                                        </h3>
                                        <p className={styles.only_show_on_desktop}>
                                            This allows us to access your usage history, so we can
                                            accurately allocate your solar credits to provide you
                                            with the best possible savings.
                                        </p>
                                        <Form
                                            form={utilityLinkForm}
                                            layout="vertical"
                                            onFinish={handleSubmit}
                                            onValuesChange={handleValuesChange}
                                        >
                                            <Form.Item
                                                name="zipcode"
                                                label="Enter your Zipcode*"
                                                rules={fieldsValidation['zipcode']}
                                                className={styles.textFieldPrimary}
                                            >
                                                <Input
                                                    type="number"
                                                    data-testid="test-zipcode-input"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        utilityLinkForm.resetFields(['utility'])
                                                        updateZipcodeState(e.target.value)
                                                    }}
                                                    size="5"
                                                    ref={searchInput}
                                                    maxLength="5"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="utility"
                                                label="Select your utility company*"
                                                className={styles.formLabel}
                                                required
                                            >
                                                <Select className={styles.selectTextField}>
                                                    {data?.utility &&
                                                        data?.utility.map((item) => {
                                                            return (
                                                                <Option
                                                                    key={item.utilityId}
                                                                    value={item.utilityName}
                                                                >
                                                                    {item.utilityName}
                                                                </Option>
                                                            )
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <div>
                                                {(!!errorMessages || !!successMessages) && (
                                                    <PepcoBox
                                                        hiddePepcoLogo={
                                                            enablePepco ? 'visible' : 'hidden'
                                                        }
                                                        connectToPepcoMessage=""
                                                        hideComments
                                                    >
                                                        <Form.Item>
                                                            <div>
                                                                <div className={styles.text_blue}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                        className={styles.no_margin}
                                                                    >
                                                                        {errorMessages}
                                                                    </p>
                                                                </div>
                                                                <div className={styles.text_blue}>
                                                                    <p
                                                                        style={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                        className={styles.no_margin}
                                                                    >
                                                                        {successMessages}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Form.Item>
                                                    </PepcoBox>
                                                )}
                                            </div>
                                            {enableContinue && (
                                                <Button
                                                    type="success"
                                                    block
                                                    // name="CONTINUE"
                                                    data-testid="CONTINUE"
                                                    htmlType="submit"
                                                    style={{
                                                        color: '#ffffff',
                                                        background: '#36CF59',
                                                        height: '70px',
                                                        borderRadius: '8px',
                                                        fontSize: '22px',
                                                        fontWeight: 'bold',
                                                        marginTop: '5px',
                                                        marginBottom: '20px'
                                                    }}
                                                >
                                                    CONTINUE
                                                </Button>
                                            )}
                                        </Form>
                                        {enablePepco && !enable2fa && (
                                            <PepcoBox
                                                handleSubmitPepco={handleSubmitPepco}
                                                loginForm={loginForm}
                                                connectToPepcoMessage={
                                                    'Connect to your Pepco account'
                                                }
                                            >
                                                <Form
                                                    form={loginForm}
                                                    layout="vertical"
                                                    onFinish={handleSubmitPepco}
                                                >
                                                    <Form.Item
                                                        onChange={() => {
                                                            setUtilityUserName(
                                                                loginForm.getFieldValue('username')
                                                            )
                                                            setFirstConnectClicked(0)
                                                        }}
                                                        className={styles.textFieldBlue}
                                                        name="username"
                                                        label="Username*"
                                                        required
                                                    >
                                                        <Input
                                                            // className={styles.textField}
                                                            type="text"
                                                            autoComplete="username"
                                                            placeholder="Enter your Pepco username/email address"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        onChange={() => {
                                                            setUtilityUserPassword(
                                                                loginForm.getFieldValue('password')
                                                            )
                                                            setFirstConnectClicked(0)
                                                        }}
                                                        name="password"
                                                        label="Password*"
                                                        required
                                                        className={styles.textFieldBlue}
                                                    >
                                                        <Input.Password
                                                            style={{
                                                                background: '#ffffff',
                                                                border: '1px solid #18acd5',
                                                                boxSizing: 'border-box',
                                                                borderRadius: '4px',
                                                                height: '42px'
                                                            }}
                                                            autoComplete="current-password"
                                                            placeholder="Enter your Pepco password"
                                                            className={styles.passwordTextField}
                                                        />
                                                    </Form.Item>
                                                    <div>
                                                        {!!errorMessagesPepco && (
                                                            <Form.Item>
                                                                <div className={styles.text_error}>
                                                                    <p>{errorMessagesPepco}</p>
                                                                </div>
                                                            </Form.Item>
                                                        )}
                                                    </div>
                                                    <Button
                                                        type="primary"
                                                        block
                                                        name="SIGN IN TO PEPCO"
                                                        onClick={() =>
                                                            setFirstConnectClicked(
                                                                () => firstConnectClicked + 1
                                                            )
                                                        }
                                                        disabled={
                                                            firstConnectClicked <= 1 &&
                                                            !!utilityUserName &&
                                                            !!utilityUserPassword
                                                                ? false
                                                                : true
                                                        }
                                                        htmlType="submit"
                                                        style={{
                                                            background: '#005EBE',
                                                            opacity:
                                                                firstConnectClicked <= 1 &&
                                                                !!utilityUserName &&
                                                                !!utilityUserPassword
                                                                    ? '100%'
                                                                    : '50%',
                                                            color: '#ffffff',
                                                            fontWeight: '500',
                                                            height: '53px',
                                                            borderRadius: '4px',
                                                            fontSize: '1rem'
                                                        }}
                                                    >
                                                        SIGN IN TO PEPCO
                                                    </Button>
                                                    <div className={styles.hr_header_div}>
                                                        <hr className={styles.hr_header}></hr>
                                                    </div>
                                                    <UploadBillModal />
                                                </Form>
                                            </PepcoBox>
                                        )}
                                        {enable2fa && (
                                            <PepcoBox
                                                connectToPepcoMessage={
                                                    'Connect to your Pepco account '
                                                }
                                            >
                                                <Form
                                                    form={faForm}
                                                    layout="vertical"
                                                    onFinish={handleSubmit2Fa}
                                                >
                                                    <p>
                                                        Please enter the code that was sent to your
                                                        phone
                                                    </p>
                                                    <Form.Item
                                                        onChange={() => {
                                                            setUtilityUserMFaCode(
                                                                faForm.getFieldValue('mfacode')
                                                            )
                                                            setSecondConnectClicked(0)
                                                            setFirstConnectClicked(0)
                                                        }}
                                                        name="mfacode"
                                                        required
                                                        className={styles.textFieldBlue}
                                                        label="Code*"
                                                    >
                                                        <Input type="text" />
                                                    </Form.Item>
                                                    <div>
                                                        {!!errorMessages2fa && (
                                                            <Form.Item>
                                                                <div className={styles.text_error}>
                                                                    <p>{errorMessages2fa}</p>
                                                                </div>
                                                            </Form.Item>
                                                        )}
                                                    </div>
                                                    <Button
                                                        type="primary"
                                                        block
                                                        data-testid="signInToPepcoMf2Code"
                                                        onClick={() =>
                                                            setSecondConnectClicked(
                                                                () => secondConnectClicked + 1
                                                            )
                                                        }
                                                        disabled={
                                                            secondConnectClicked <= 1 &&
                                                            !!utilityUserMFaCode
                                                                ? false
                                                                : true
                                                        }
                                                        htmlType="submit"
                                                        style={{
                                                            background: '#005EBE',
                                                            opacity:
                                                                secondConnectClicked <= 1 &&
                                                                !!utilityUserMFaCode
                                                                    ? '100%'
                                                                    : '50%',
                                                            color: '#ffffff',
                                                            fontWeight: '500',
                                                            height: '53px',
                                                            borderRadius: '4px',
                                                            fontSize: '1rem'
                                                        }}
                                                    >
                                                        SIGN IN TO PEPCO
                                                    </Button>
                                                </Form>
                                            </PepcoBox>
                                        )}
                                        {subscriberPolling && (
                                            <PepcoBox
                                                connectToPepcoMessage={
                                                    'Connecting to your Pepco account'
                                                }
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        color: '#005EBE',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        fontSize: '14px',
                                                        lineHeight: '140%',
                                                        marginBottom: '0.5rem'
                                                    }}
                                                >
                                                    {pepcoSignIn.message}
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <Progress
                                                        status={'normal'}
                                                        percent={100}
                                                        style={{
                                                            width: '-webkit-fill-available',
                                                            margin: '0 3px'
                                                        }}
                                                        showInfo={false}
                                                    />
                                                    <Progress
                                                        status={'normal'}
                                                        percent={
                                                            pepcoSignIn.status === 1 ||
                                                            pepcoSignIn.status === 2
                                                                ? 100
                                                                : 0
                                                        }
                                                        style={{
                                                            width: '-webkit-fill-available',
                                                            margin: '0 3px'
                                                        }}
                                                        showInfo={false}
                                                    />
                                                    <Progress
                                                        status={'normal'}
                                                        percent={pepcoSignIn.status === 2 ? 100 : 0}
                                                        style={{
                                                            width: '-webkit-fill-available',
                                                            margin: '0 3px'
                                                        }}
                                                        showInfo={false}
                                                    />
                                                </div>
                                            </PepcoBox>
                                        )}
                                    </div>
                                </Fragment>
                            </Col>
                        </Row>
                    </>
                </Col>
                <Col xs={24} xl={12} className={styles.spots_remaining_utility}>
                    <div className={styles.spots_remaining_utility_div}>
                        <SpotsRemainingComponent />
                    </div>
                </Col>
            </Row>
            <div className={styles.advantages_component_utility}>
                <AdvantagesComponent />
            </div>
            <div className={styles.reviews_component}>
                <ReviewsComponent />
            </div>
            <div className={styles.hr_header_div}>
                <hr className={styles.hr_header}></hr>
            </div>
            <div className={styles.signup_now_utility}>
                <SignUpNowButton handleFocus={handleFocus} />
            </div>
            <div>
                <FooterComponent />
            </div>
        </>
    )
}

export default UtilityLinkForm
