import React from 'react'

const ChatBubbleIcon = () => {
    return (
        <div style={{marginRight: '3%'}}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="chat_bubble_outline_24px">
                    <path
                        id="icon/communication/chat_bubble_outline_24px"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H6L2 22V4C2 2.9 2.9 2 4 2ZM6 16H20V4H4V18L6 16Z"
                        fill="white"
                    />
                </g>
            </svg>
        </div>
    )
}

export default ChatBubbleIcon
