import React, {useEffect} from 'react'
import {notification} from 'antd'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import DashboardPage from '../DashboardPage/DashboardPage'
import LoginPage from '../LoginPage/LoginPage'
import AuthenticatedRoute from '../Common/AuthenticatedRoute/AuthenticatedRoute'
import routes from '../../routes'
import OnboardingPage from '../OnboardingPage/OnboardingPage'
import {OnboardingPageContextProvider} from '../Contexts/OnboardingPageContext'

function App(props) {
    useEffect(() => {
        notification.config({
            placement: 'bottomRight'
        })
    }, [])
    return (
        <OnboardingPageContextProvider>
            <BrowserRouter>
                <Switch>
                    <Route path={routes.login} component={LoginPage} />
                    <Route
                        path={routes.onBoarding}
                        render={(props) => <OnboardingPage client={props.client} />}
                    />
                    <AuthenticatedRoute path={routes.dashboard} component={DashboardPage} />
                </Switch>
            </BrowserRouter>
        </OnboardingPageContextProvider>
    )
}

export default App
