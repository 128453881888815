import {Drawer, Grid, Layout, Menu} from 'antd'
import {SettingFilled, PoweroffOutlined} from '@ant-design/icons'
import {Fragment} from 'react'
import {logoImage} from '../../../assets'
import styles from './Sidebar.module.css'
import routes from '../../../routes'
import {signOut} from '../../auth/auth'
import {useHistory, useLocation} from 'react-router-dom'
import classnames from 'classnames'

const {Sider} = Layout
const {useBreakpoint} = Grid

function SidebarContent({isMobile}) {
    const location = useLocation()
    const history = useHistory()

    const menuClassName = classnames(styles.menu, {[styles.menuMobile]: isMobile})

    function handleClick(e) {
        history.push(e.key)
    }

    function handleSignOut() {
        signOut()
    }

    return (
        <Fragment>
            <div className={styles.logoContainer}>
                <img src={logoImage} alt="save-solar-logo" className={styles.logo} />
            </div>
            <Menu
                className={menuClassName}
                theme="dark"
                defaultSelectedKeys={[location.pathname]}
                mode="inline"
            >
                <Menu.Item key={routes.settings} icon={<SettingFilled />} onClick={handleClick}>
                    Settings
                </Menu.Item>
                <Menu.Item key="singOut" icon={<PoweroffOutlined />} onClick={handleSignOut}>
                    Sing out
                </Menu.Item>
            </Menu>
        </Fragment>
    )
}

function Sidebar({collapsed, onToggle, ...props}) {
    const screens = useBreakpoint()
    const isMobile = screens.xs || !screens.lg

    function handleClose() {
        onToggle(false)
    }
    return isMobile ? (
        <Drawer
            className={styles.drawer}
            placement="left"
            closable={false}
            visible={collapsed}
            onClose={handleClose}
            width={200}
        >
            <SidebarContent isMobile={isMobile} />
        </Drawer>
    ) : (
        <Sider collapsible className={styles.sidebar} trigger={null} collapsed={collapsed}>
            <SidebarContent isMobile={isMobile} />
        </Sider>
    )
}

export default Sidebar
