import React from 'react'

const CameraIcon = () => {
    return (
        <div style={{marginRight: '3%'}}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="camera_enhance_24px">
                    <path
                        id="icon/action/camera_enhance_24px"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20 5H16.83L15 3H9L7.17 5H4C2.9 5 2 5.9 2 7V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V7C22 5.9 21.1 5 20 5ZM12 10L11.06 12.06L9 13C9 11.35 10.35 10 12 10ZM15 13C15 11.35 13.65 10 12 10L12.94 12.06L15 13ZM12 16L12.94 13.94L15 13C15 14.65 13.65 16 12 16ZM12 16L11.06 13.94L9 13C9 14.65 10.35 16 12 16ZM4 19H20V7H15.95L15.36 6.35L14.12 5H9.88L8.64 6.35L8.05 7H4V19ZM12 8C9.24 8 7 10.24 7 13C7 15.76 9.24 18 12 18C14.76 18 17 15.76 17 13C17 10.24 14.76 8 12 8Z"
                        fill="white"
                    />
                </g>
            </svg>
        </div>
    )
}

export default CameraIcon
