import {useState, useEffect, Fragment, useRef} from 'react'
import {Form, Input, Modal, Button, InputNumber, Row, Col} from 'antd'
import {callSignUpPost} from '../../api/call-api'
import {leadTrackingPost} from '../../api/call-api-axios'
import {useCallback} from 'react'
import styles from './WaitlistForm.module.css'
import './WaitlistForm.responsive.css'
import FooterComponent from '../CommonComponents/FooterComponent'
import HeaderComponent from '../CommonComponents/HeaderComponent'
import SignUpNowButton from '../CommonComponents/SignUpNowButton'
import ReviewsComponent from '../CommonComponents/ReviewsComponent'
import AdvantagesComponent from '../CommonComponents/AdvantagesComponent'
import TermsOfService from '../AccountCreationForm/TermsOfService'
import PrivacyPolicy from '../AccountCreationForm/PrivacyPolicy'
import jwt from 'jsonwebtoken'
import {googleTagWaitListSuccess, googleTagWaitListFailed} from '../../util/googleTagManager'

const fieldsValidation = {
    firstName: [
        {
            required: true,
            message: 'Please enter a first name.'
        }
    ],
    lastName: [{required: true, message: 'Please enter a last name.'}],
    email: [
        {required: true, message: 'Please enter an email address.'},
        {
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: 'Please enter a valid email.'
        }
    ],
    phoneNumber: [
        {required: true, message: 'Please enter a phone number.'},
        {
            validator: async (_, value) => {
                const stringValue = value && value.toString()
                if (stringValue && stringValue.length !== 10) {
                    throw new Error('Please enter a valid phone number.')
                }
            },
            message: 'Please enter a valid phone number.'
        }
    ],
    password: [{required: true, message: 'Please enter a password.'}]
}

function phoneFormatter(value) {
    const cleaned = ('' + value).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return value
}

function decodedToken(token) {
    return jwt.decode(token).data.subscriberId
}

function WaitlistForm() {
    const [waitListForm] = Form.useForm()
    const utmParameters = JSON.parse(localStorage.getItem('utmParameters'))

    const [showTos, setShowTos] = useState(false)
    const [showPp, setShowPp] = useState(false)
    const [errorMessages, setErrorMessages] = useState()
    const [successMessages, setSuccessMessages] = useState()
    const [trackingId, setTrackingId] = useState()
    const [disableOnClick, setDisableOnClick] = useState(0)
    const [userName, setUserName] = useState('')
    const [userLastName, setUserLastName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userPhone, setUserPhone] = useState('')
    const [isTrackingIdValid, setIsTrackingIdValid] = useState(false)

    function toggleTos(e) {
        e.preventDefault() //Prevent to toggle TOS checkbox
        setShowTos(!showTos)
    }

    function togglePp(e) {
        e.preventDefault() //Prevent to toggle PP checkbox
        setShowPp(!showPp)
    }

    function resetLocalStorage() {
        localStorage.clear()
        const queryParams = new URLSearchParams(window.location.search)
        const utmParameters = {
            utmCampaign: queryParams.get('utm_campaign'),
            utmSource: queryParams.get('utm_source'),
            utmMedium: queryParams.get('utm_medium'),
            timeClicked: new Date(),
            zipCode: queryParams.get('zip_code'),
            firstName: queryParams.get('first_name') || '',
            lastName: queryParams.get('last_name') || '',
            email: queryParams.get('email') || '',
            phoneNumber: queryParams.get('phone') || ''
        }
        localStorage.setItem('utmParameters', JSON.stringify(utmParameters))
    }
   
    async function handleSubmit(values) {
        let body = {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: '' + values.phoneNumber,
            trackingId: trackingId,
            subscriberStatus: 'WAITLIST'
        }

        callSignUpPost(
            body,
            (res) => {
                if (res.message === 'Onboarding in progress') {
                    setErrorMessages(
                        'It looks like you are already in our system. We have sent you an email with a link to resume the process.'
                    )
                } else if (res.message === 'Onboarding completed already') {
                    setErrorMessages(
                        'It looks like you are already on the waiting list. We will let you know when you will start receiving credits.'
                    )
                } else if (res.message === 'Subscriber created') {
                    const newSubscriberId = decodedToken(localStorage.getItem('substkn'))
                    const newZipCode = JSON.parse(localStorage.getItem('utmParameters')).zipCode
                    googleTagWaitListSuccess(body, newZipCode, newSubscriberId)
                    resetLocalStorage()
                    setSuccessMessages(
                        'Great! now your are on the waiting list. We will let you know when you will start receiving credits.'
                    )
                }
            },
            (err) => {
                console.log(err)
                setErrorMessages(
                    'An error has occured.  Please try again, or contact support@savesolar.us for assistance.'
                )
                const newZipCode = JSON.parse(localStorage.getItem('utmParameters')).zipCode
                googleTagWaitListFailed(body, newZipCode)
            }
        )
    }

    const searchInput = useRef(null)

    function handleFocus() {
        searchInput.current.focus()
    }

    const getTrackingId = useCallback(async () => {
        try {
            if(utmParameters && (utmParameters.utmCampaign || utmParameters.utmMedium || utmParameters.utmSource )){
                const tracking = await leadTrackingPost(utmParameters)
                tracking.message === 'Network Error'
                    ? localStorage.removeItem('zipCode')
                    : localStorage.setItem('zipCode', parseInt(utmParameters.zipCode))
                setTrackingId(tracking)
            }
        } catch (error) {
            console.error(error)
        }
    }, [utmParameters])
    
    useEffect(() => {
        if (!isTrackingIdValid && (!trackingId || trackingId === 'undefined')) {
           getTrackingId()
           setIsTrackingIdValid(true);
        }

        return () => {
            setIsTrackingIdValid(true);
        };
    }, [isTrackingIdValid, trackingId, getTrackingId])

    useEffect(() => {
        const initialParams = {
            firstName: utmParameters.firstName,
            lastName: utmParameters.lastName,
            email: utmParameters.email,
            phoneNumber: parseInt(utmParameters.phoneNumber)
        }

        setUserName(initialParams.firstName)
        setUserLastName(initialParams.lastName)
        setUserEmail(initialParams.email)
        setUserPhone(initialParams.phoneNumber)

        waitListForm.setFieldsValue(initialParams)
        
    }, [
        waitListForm,
        utmParameters.firstName,
        utmParameters.lastName,
        utmParameters.email,
        utmParameters.phoneNumber
    ])

    return (
        <>
            <div>
                <HeaderComponent />
            </div>
            <Row>
                <Col xs={24} xl={12} className={styles.frame_left_column}>
                    <>
                        <Row>
                            <Col xs={24} xl={24}>
                                <div className={styles.highlight_box}>
                                    <h1 className={'blue_header'}>We are coming soon!</h1>
                                    <h2 className={'no_capacity_text'}>
                                        We don’t have any capacity in your area at the moment
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} xl={24}>
                                <h3 className={'no_capacity_paragraph'}>
                                    We’re building new solar farms all the time. Enter your details
                                    below, and we’ll be in touch as soon as more capacity becomes
                                    available.
                                </h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} xl={24}>
                                <Fragment>
                                    <Form
                                        form={waitListForm}
                                        layout="vertical"
                                        onFinish={handleSubmit}
                                        className={'accountCreation'}
                                    >
                                        <Form.Item
                                            name="firstName"
                                            label="First Name*"
                                            onChange={(e) => {
                                                setErrorMessages()
                                                setDisableOnClick(0)
                                                setUserName(e.target.value)
                                            }}
                                            className={'textFieldPrimary'}
                                            style={{
                                                display: successMessages ? 'none' : ''
                                            }}
                                            rules={fieldsValidation['firstName']}
                                        >
                                            <Input
                                                ref={searchInput}
                                                placeholder="Enter first name"
                                                type="text"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="lastName"
                                            label="Last Name*"
                                            onChange={(e) => {
                                                setErrorMessages()
                                                setDisableOnClick(0)
                                                setUserLastName(e.target.value)
                                            }}
                                            className={'textFieldPrimary'}
                                            style={{
                                                display: successMessages ? 'none' : ''
                                            }}
                                            rules={fieldsValidation['lastName']}
                                        >
                                            <Input type="text" placeholder="Enter last name" />
                                        </Form.Item>
                                        <Form.Item
                                            name="email"
                                            label="Email*"
                                            onChange={(e) => {
                                                setErrorMessages()
                                                setDisableOnClick(0)
                                                setUserEmail(e.target.value)
                                            }}
                                            className={'textFieldPrimary'}
                                            style={{
                                                display: successMessages ? 'none' : ''
                                            }}
                                            rules={fieldsValidation['email']}
                                        >
                                            <Input
                                                type="email"
                                                autoComplete="off"
                                                placeholder="Enter e-mail ID"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="phoneNumber"
                                            label="Phone Number*"
                                            onChange={(e) => {
                                                setErrorMessages()
                                                setDisableOnClick(0)
                                                setUserPhone(e.target.value)
                                            }}
                                            className={'textFieldPrimaryNumber'}
                                            style={{
                                                display: successMessages ? 'none' : ''
                                            }}
                                            rules={fieldsValidation['phoneNumber']}
                                        >
                                            <InputNumber
                                                formatter={phoneFormatter}
                                                placeholder="Enter phone no."
                                                style={{
                                                    width: '100%',
                                                    height: '42px',
                                                    boxSizing: 'border-box',
                                                    borderRadius: '4px'
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="tos"
                                            valuePropName="checked"
                                            style={{display: successMessages ? 'none' : ''}}
                                        >
                                            <p className={'checkbox_text'}>
                                                By clicking on the button you agree to the
                                                <a className="tos" href="/#" onClick={toggleTos}>
                                                    &nbsp;Terms of Service&nbsp;
                                                </a>{' '}
                                                and
                                                <a className="tos" href="/#" onClick={togglePp}>
                                                    &nbsp;Privacy Policy
                                                </a>
                                            </p>
                                        </Form.Item>
                                        <div>
                                            {(!!errorMessages || !!successMessages) && (
                                                <Form.Item>
                                                    <div>
                                                        <div className={styles.text_error}>
                                                            <p className={styles.no_margin}>
                                                                {errorMessages}
                                                            </p>
                                                        </div>
                                                        <div className={'text_green'}>
                                                            <p className={styles.no_margin}>
                                                                {successMessages}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Form.Item>
                                            )}
                                        </div>
                                        <Button
                                            className={styles.join_the_waitlist_button}
                                            type="success"
                                            size="large"
                                            block
                                            onClick={() => {
                                                if (userEmail?.length) {
                                                    window._hsq.push(['setPath', '/waitlist'])
                                                    window._hsq.push([
                                                        'identify',
                                                        {
                                                            email: userEmail
                                                        }
                                                    ])
                                                    window._hsq.push(['trackPageView'])
                                                }
                                                setDisableOnClick(() => disableOnClick + 1)
                                            }}
                                            disabled={
                                                disableOnClick > 1 ||
                                                errorMessages ||
                                                userName === '' ||
                                                userLastName === '' ||
                                                userEmail === '' ||
                                                userPhone === '' ||
                                                userPhone.length < 10
                                                    ? true
                                                    : false
                                            }
                                            htmlType="submit"
                                            style={{
                                                color: '#ffffff',
                                                background: '#36CF59',
                                                opacity:
                                                    disableOnClick > 1 ||
                                                    errorMessages ||
                                                    userName === '' ||
                                                    userLastName === '' ||
                                                    userEmail === '' ||
                                                    userPhone === '' ||
                                                    userPhone.length < 10
                                                        ? 0.5
                                                        : 1,
                                                display: successMessages ? 'none' : '',
                                                height: '70px',
                                                borderRadius: '8px',
                                                fontSize: '22px',
                                                fontWeight: 'bold',
                                                marginTop: '-10px'
                                            }}
                                        >
                                            JOIN THE WAITLIST
                                        </Button>
                                    </Form>
                                    <Modal
                                        visible={showTos}
                                        width={1000}
                                        onCancel={toggleTos}
                                        footer={[
                                            <Button key="back" type="primary" onClick={toggleTos}>
                                                Close
                                            </Button>
                                        ]}
                                        centered
                                    >
                                        <TermsOfService />
                                    </Modal>
                                    <Modal
                                        visible={showPp}
                                        width={1000}
                                        onCancel={togglePp}
                                        footer={[
                                            <Button key="back" type="primary" onClick={togglePp}>
                                                Close
                                            </Button>
                                        ]}
                                        centered
                                    >
                                        <PrivacyPolicy />
                                    </Modal>
                                </Fragment>
                            </Col>
                        </Row>
                    </>
                </Col>
                <Col xs={24} xl={12}>
                    <div className={'advantages_component'}>
                        {' '}
                        <AdvantagesComponent />
                    </div>
                    <hr className={styles.hr_header}></hr>
                </Col>
            </Row>
            <div>{(!!errorMessages || !!successMessages) && <ReviewsComponent />}</div>
            <div style={{marginTop: '1rem'}} className="sign-up_button">
                <SignUpNowButton handleFocus={handleFocus} />
            </div>
            <div>
                <FooterComponent />
            </div>
        </>
    )
}

export default WaitlistForm
