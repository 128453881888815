import {useQuery} from '@apollo/client'
import {useEffect} from 'react'
import {Route, useHistory} from 'react-router-dom'
import {currentUserVar} from '../../../graphQL/cache'
import {CURRENT_USER} from '../../../graphQL/queries/user'
import {getSession} from '../../auth/auth'

const authorize = (updateCurrentUser, currentUser) =>
    new Promise((resolve) => {
        getSession(updateCurrentUser, currentUser, resolve)
    })

function AuthenticatedRoute(props) {
    const history = useHistory()
    const {
        data: {currentUser}
    } = useQuery(CURRENT_USER)

    function updateCurrentUser(value) {
        currentUserVar(value)
    }

    useEffect(() => {
        async function checkAuthorized() {
            const authorized = await authorize(updateCurrentUser, currentUser)
            if (!authorized) {
                history.push('/login')
            }
        }
        checkAuthorized()
    }, [currentUser, history])

    return <Route {...props} />
}

export default AuthenticatedRoute
