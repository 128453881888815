import React, {useState, useEffect, Fragment, useRef, useCallback, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {Form, Input, Modal, Button, InputNumber, Row, Col} from 'antd'
import routes from '../../../routes'
import {callSignUpPost} from '../../api/call-api'
import {callAuthenticatePost, callSubscriberGet} from '../../api/call-api-axios'
import HeaderComponent from '../CommonComponents/HeaderComponent'
import ReviewsComponent from '../CommonComponents/ReviewsComponent'
import SpotsRemainingComponent from '../CommonComponents/SpotsRemainingComponent'
import SpotsLeftComponent from '../CommonComponents/SpotsLeftComponent'
import AdvantagesComponent from '../CommonComponents/AdvantagesComponent'
import TermsOfService from './TermsOfService'
import PrivacyPolicy from './PrivacyPolicy'
import SignUpNowButton from '../CommonComponents/SignUpNowButton'

import styles from './AccountCreationForm.module.css'
import './AccountCreationForm.responsive.css'

import {imgBreadCrumb1Mobile} from '../../../assets'

import {leadTrackingPost, callSignUpPut} from '../../api/call-api-axios'

import FooterComponent from '../CommonComponents/FooterComponent'
import OnboardingPageContext from '../../Contexts/OnboardingPageContext'
import Schema from 'async-validator'
import jwt from 'jsonwebtoken'
import {googleTagOnboardingSuccess, googleTagOnboardingFailed} from '../../util/googleTagManager'

//AntD uses async-validator, but does not provide a way to access its options.
// Overriding warning spam from email form field regex validator:
Schema.warning = function () {}

const fieldsValidation = {
    firstName: [
        {
            required: true,
            message: 'Please enter a first name.'
        }
    ],
    lastName: [{required: true, message: 'Please enter a last name.'}],
    email: [
        {required: true, message: 'Please enter an email address.'},
        {
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: 'Please enter a valid email.'
        }
    ],
    phoneNumber: [
        {required: true, message: 'Please enter a phone number.'},
        {
            validator: async (_, value) => {
                const stringValue = value && value.toString()
                if (stringValue && stringValue.length !== 10) {
                    throw new Error('Please enter a valid phone number.')
                }
            },
            message: 'Please enter a valid phone number.'
        }
    ],
    password: [{required: true, message: 'Please enter a password.'}]
}

function phoneFormatter(value) {
    const cleaned = ('' + value).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return value
}

function AccountCreationForm(props) {
    const history = useHistory()
    const [onboardingForm] = Form.useForm()
    const onboardingPageContext = useContext(OnboardingPageContext)
    const tempLinkId = props.match.params.tempLinkId
    const utmParameters = JSON.parse(localStorage.getItem('utmParameters'))
    const [showTos, setShowTos] = useState(false)
    const [showPp, setShowPp] = useState(false)
    const [errorMessages, setErrorMessages] = useState()
    const [trackingId, setTrackingId] = useState()
    const [disableOnClick, setDisableOnClick] = useState(0)

    onboardingPageContext.subscriber = {
        firstName:
            utmParameters.firstName === ''
                ? onboardingPageContext.subscriber.firstName
                : utmParameters.firstName,
        lastName:
            utmParameters.lastName === ''
                ? onboardingPageContext.subscriber.lastName
                : utmParameters.lastName,
        email:
            utmParameters.email === ''
                ? onboardingPageContext.subscriber.email
                : utmParameters.email,
        phoneNumber:
            utmParameters.phoneNumber === ''
                ? onboardingPageContext.subscriber.phoneNumber
                : utmParameters.phoneNumber
    }
    let subscriberContext = onboardingPageContext.subscriber

    function toggleTos(e) {
        e.preventDefault() //Prevent to toggle TOS checkbox
        setShowTos(!showTos)
    }

    function togglePp(e) {
        e.preventDefault() //Prevent to toggle PP checkbox
        setShowPp(!showPp)
    }

    const onChangeValues = (key, value) => {
        onboardingPageContext.setSubscriber({
            ...onboardingPageContext.subscriber,
            [key]: value
        })
    }

    function decodedToken(token) {
        return jwt.decode(token).data.subscriberId
    }

    const getTrackingId = useCallback(async () => {
        try {
            if (
                utmParameters &&
                (utmParameters.utmCampaign || utmParameters.utmMedium || utmParameters.utmSource)
            ) {
                const tracking = await leadTrackingPost(utmParameters)
                tracking.message === 'Network Error'
                    ? localStorage.removeItem('zipCode')
                    : localStorage.setItem('zipCode', parseInt(utmParameters.zipCode))
                setTrackingId(tracking)
            }
        } catch (error) {
            console.error(error)
        }
    }, [utmParameters])

    async function handleSubmit(values) {
        localStorage.setItem('firstName', values.firstName)

        let body = {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: '' + values.phoneNumber,
            trackingId: trackingId
        }

        let authorizationToken = localStorage.getItem('substkn')

        if (authorizationToken !== null && authorizationToken !== 'null') {
            try {
                if (body.email === localStorage.getItem('email')) {
                    delete body.email
                }
                const dataUpdated = await callSignUpPut(body)
                if (dataUpdated.message === 'Updated') {
                    const newSubscriberId = decodedToken(localStorage.getItem('substkn'))
                    const newZipCode = localStorage.getItem('zipCode')
                    googleTagOnboardingSuccess(body, newZipCode, newSubscriberId)

                    localStorage.setItem('email', values.email)
                    history.push(routes.onBoardingUtility)
                } else {
                    setErrorMessages(
                        'This email already exists. Check your inbox for a link to resume the signup process.'
                    )
                }
            } catch (error) {
                if (error.message === 'Token is not valid') {
                    console.error(error)
                    resetStates()
                    history.push(routes.onBoardingSignup)
                } else {
                    console.error(error)
                    setErrorMessages(
                        'An error has occurred.  Please try again, or contact support@savesolar.us for assistance.'
                    )
                    const newZipCode = localStorage.getItem('zipCode')
                    googleTagOnboardingFailed(body, newZipCode)
                }
            }
        } else {
            callSignUpPost(
                body,
                (res) => {
                    if (res.message === 'Onboarding in progress') {
                        setErrorMessages(
                            'It looks like you are already in our system. We have sent you an email with a link to resume the process.'
                        )
                    } else if (res.message === 'Onboarding completed already') {
                        setErrorMessages(
                            'It looks like you are already on the waiting list. We will let you know when you will start receiving credits.'
                        )
                    } else if (res.message === 'Subscriber created') {
                        const newSubscriberId = decodedToken(localStorage.getItem('substkn'))
                        const newZipCode = localStorage.getItem('zipCode')
                        googleTagOnboardingSuccess(body, newZipCode, newSubscriberId)

                        history.push(routes.onBoardingUtility)
                    }
                },
                (err) => {
                    console.log(err)
                    setErrorMessages(
                        'An error has occurred.  Please try again, or contact support@savesolar.us for assistance.'
                    )
                    const newZipCode = localStorage.getItem('zipCode')
                    googleTagOnboardingFailed(body, newZipCode)
                }
            )
        }
    }

    const searchInput = useRef(null)

    function handleFocus() {
        searchInput.current.focus()
    }

    const resetStates = useCallback(() => {
        localStorage.clear()
        onboardingForm.resetFields()
        const queryParams = new URLSearchParams(window.location.search)
        const utmParameters = {
            utmCampaign: queryParams.get('utm_campaign'),
            utmSource: queryParams.get('utm_source'),
            utmMedium: queryParams.get('utm_medium'),
            timeClicked: new Date(),
            zipCode: queryParams.get('zip_code'),
            firstName: queryParams.get('first_name') || '',
            lastName: queryParams.get('last_name') || '',
            email: queryParams.get('email') || '',
            phoneNumber: queryParams.get('phone') || ''
        }
        localStorage.setItem('utmParameters', JSON.stringify(utmParameters))
    }, [onboardingForm])

    const getSusbcriber = useCallback(
        async (isTempLinkEnabled) => {
            try {
                const response = await callSubscriberGet()

                const data = response.data
                if (response.status === 200 && data) {
                    const {email, firstName, lastName, phoneNumber, subscriberStatus} = data

                    const bodyData = {
                        email: email,
                        firstName: firstName,
                        lastName: lastName,
                        phoneNumber: '' + phoneNumber
                    }

                    onboardingPageContext.setSubscriber(bodyData)

                    onboardingForm.setFieldsValue(bodyData)

                    history.push(routes.onBoardingSignup)

                    if (isTempLinkEnabled && subscriberStatus === 'UTILITY VERIFICATION') {
                        history.push(routes.onBoardingUtility)
                    }
                } else {
                    setErrorMessages(
                        'An error has occurred.  Please try again, or contact support@savesolar.us for assistance.'
                    )
                    localStorage.clear()
                }
            } catch (error) {
                if (error.message === 'Token is not valid') {
                    resetStates()
                    console.error(error)
                    history.push(routes.onBoardingSignup)
                } else {
                    resetStates()
                    console.error(error.message)
                }
            }
        },
        [onboardingForm, resetStates, history, onboardingPageContext]
    )

    const authenticateExistingSubscriber = useCallback(async () => {
        try {
            const response = await callAuthenticatePost(tempLinkId)

            const token = response.headers.token
            if (response.status === 200 && token) {
                history.push(routes.onBoardingSignup)
                localStorage.setItem('substkn', token)
            }
            await getSusbcriber(true)
        } catch (error) {
            resetStates()

            if (error.response && error.response.status === 403) {
                setErrorMessages(
                    `${error.response.data.message}. We have sent you an email with a new link to resume the process.`
                )
            }
        }
    }, [tempLinkId, history, resetStates, getSusbcriber])

    useEffect(() => {
        if (!trackingId || trackingId === 'undefined') {
            getTrackingId()
        }
    }, [trackingId, getTrackingId])

    useEffect(() => {
        const authorizationToken = localStorage.getItem('substkn')
        if (
            authorizationToken &&
            !onboardingForm.getFieldValue('firstName') &&
            !onboardingForm.getFieldValue('lastName') &&
            !onboardingForm.getFieldValue('email') &&
            !onboardingForm.getFieldValue('phoneNumber')
        ) {
            getSusbcriber()
        }
    }, [getSusbcriber, onboardingForm])

    useEffect(() => {
        if (tempLinkId) {
            authenticateExistingSubscriber()
        }
    }, [authenticateExistingSubscriber, tempLinkId])

    useEffect(() => {
        const initialParams = {
            firstName: utmParameters.firstName,
            lastName: utmParameters.lastName,
            email: utmParameters.email,
            phoneNumber: parseInt(utmParameters.phoneNumber)
        }
        onboardingForm.setFieldsValue(initialParams)
    }, [
        onboardingForm,
        utmParameters.firstName,
        utmParameters.lastName,
        utmParameters.email,
        utmParameters.phoneNumber
    ])

    return (
        <>
            <div>
                <HeaderComponent />
            </div>
            <Row>
                <Col xs={24} xl={12} className={'frame_left_column'}>
                    <>
                        <Row>
                            <Col xs={24} xl={24}>
                                <div className={'highlight_box'}>
                                    <h1 className={'greeting_header'}>Great News!</h1>
                                    <div style={{zIndex: '1'}}>
                                        <h2 className={'highlighted_text'}>
                                            You have a{' '}
                                            <span className={'highlighted_text_solar'}>
                                                solar farm nearby
                                            </span>
                                        </h2>
                                    </div>
                                    <div className={'blue_highlight'} />
                                </div>
                            </Col>
                        </Row>
                        <div className="spots_left_component">
                            <SpotsLeftComponent />
                        </div>
                        <Row>
                            <Col xs={24} xl={24}>
                                <h3 className="savings_message">
                                    You're just a few clicks away from saving
                                    <span className={styles.text_highlight}> 5%-10% </span>
                                    on your electricity bill
                                </h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} xl={24}>
                                <div className="breadcrumb_container">
                                    <img
                                        alt="1st phase logo"
                                        src={imgBreadCrumb1Mobile}
                                        className={'logo_breadcrumb'}
                                    />
                                    <img
                                        alt="1st phase logo"
                                        src={imgBreadCrumb1Mobile}
                                        className={'logo_breadcrumb_mobile'}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} xl={24}>
                                <Fragment>
                                    <Form
                                        form={onboardingForm}
                                        layout="vertical"
                                        onFinish={handleSubmit}
                                    >
                                        <Form.Item
                                            name="firstName"
                                            label="First name*"
                                            rules={fieldsValidation['firstName']}
                                            onChange={(e) => {
                                                setErrorMessages()
                                                setDisableOnClick(0)
                                                onChangeValues('firstName', e.target.value)
                                            }}
                                            className={'textFieldPrimary'}
                                        >
                                            <Input
                                                ref={searchInput}
                                                value={subscriberContext.firstName}
                                                type="text"
                                                placeholder="Enter first name"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="lastName"
                                            label="Last name*"
                                            rules={fieldsValidation['lastName']}
                                            onChange={(e) => {
                                                setErrorMessages()
                                                setDisableOnClick(0)
                                                onChangeValues('lastName', e.target.value)
                                            }}
                                            className={'textFieldPrimary'}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Enter last name"
                                                value={subscriberContext.lastName}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="email"
                                            label="E-mail*"
                                            rules={fieldsValidation['email']}
                                            onChange={(e) => {
                                                setErrorMessages()
                                                setDisableOnClick(0)
                                                onChangeValues('email', e.target.value)
                                            }}
                                            className={'textFieldPrimary'}
                                        >
                                            <Input
                                                type="email"
                                                autoComplete="off"
                                                placeholder="Enter e-mail ID"
                                                value={subscriberContext.email}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="phoneNumber"
                                            label="Phone No.*"
                                            rules={fieldsValidation['phoneNumber']}
                                            onChange={(e) => {
                                                setErrorMessages()
                                                setDisableOnClick(0)
                                                onChangeValues('phoneNumber', e.target.value)
                                            }}
                                            className={'textFieldPrimary'}
                                        >
                                            <InputNumber
                                                style={{
                                                    width: '100%',
                                                    height: '42px',
                                                    boxSizing: 'border-box',
                                                    borderRadius: '4px'
                                                }}
                                                placeholder="Enter phone no."
                                                formatter={phoneFormatter}
                                                value={subscriberContext.phoneNumber}
                                            />
                                        </Form.Item>

                                        <Form.Item name="tos" valuePropName="checked">
                                            {/* <Checkbox
                                                onChange={(e) => {
                                                    if (checkedValue === true) {
                                                        onChangeValues('tos', false)
                                                        setCheckedValue(false)
                                                    } else {
                                                        onChangeValues('tos', true)
                                                        setCheckedValue(true)
                                                    }

                                                    setErrorMessages('')
                                                    setDisableOnClick(0)
                                                }}
                                                style={{marginBottom: '-10px'}}
                                            > */}
                                            By clicking on the button you agree to the
                                            <a className="tos" href="/#" onClick={toggleTos}>
                                                &nbsp;Terms of Service&nbsp;
                                            </a>{' '}
                                            and
                                            <a className="tos" href="/#" onClick={togglePp}>
                                                &nbsp;Privacy Policy
                                            </a>
                                            {/* </Checkbox> */}
                                        </Form.Item>
                                        <div>
                                            {!!errorMessages && (
                                                <Form.Item name="messages">
                                                    <div className={styles.text_error}>
                                                        <p>{errorMessages}</p>
                                                    </div>
                                                </Form.Item>
                                            )}
                                        </div>
                                        <Button
                                            name="LET'S GO GREEN"
                                            type="success"
                                            size="large"
                                            block
                                            onClick={() => {
                                                if (
                                                    onboardingForm?.getFieldValue('email')?.length
                                                ) {
                                                    window._hsq.push(['setPath', '/signup'])
                                                    window._hsq.push([
                                                        'identify',
                                                        {
                                                            email: onboardingForm.getFieldValue(
                                                                'email'
                                                            )
                                                        }
                                                    ])
                                                    window._hsq.push(['trackPageView'])
                                                }
                                                setDisableOnClick(() => disableOnClick + 1)
                                            }}
                                            disabled={
                                                disableOnClick > 1 ||
                                                errorMessages ||
                                                onboardingForm.getFieldValue('firstName') === '' ||
                                                onboardingForm.getFieldValue('lastName') === '' ||
                                                onboardingForm.getFieldValue('email') === '' ||
                                                onboardingForm.getFieldValue('phoneNumber') ===
                                                    '' ||
                                                parseInt(
                                                    onboardingForm.getFieldValue('phoneNumber')
                                                ).toString().length < 10
                                                    ? true
                                                    : false
                                            }
                                            htmlType="submit"
                                            style={{
                                                color: '#ffffff',
                                                background: '#36CF59',
                                                opacity:
                                                    disableOnClick > 1 ||
                                                    errorMessages ||
                                                    onboardingForm.getFieldValue('firstName') ===
                                                        '' ||
                                                    onboardingForm.getFieldValue('lastName') ===
                                                        '' ||
                                                    onboardingForm.getFieldValue('email') === '' ||
                                                    onboardingForm.getFieldValue('phoneNumber') ===
                                                        '' ||
                                                    parseInt(
                                                        onboardingForm.getFieldValue('phoneNumber')
                                                    ).toString().length < 10
                                                        ? 0.5
                                                        : 1,
                                                height: '70px',
                                                borderRadius: '8px',
                                                fontSize: '22px',
                                                fontWeight: 'bold',
                                                marginTop: '-10px'
                                            }}
                                        >
                                            CONTINUE
                                        </Button>
                                    </Form>
                                    <Modal
                                        visible={showTos}
                                        width={1000}
                                        onCancel={toggleTos}
                                        footer={[
                                            <Button key="back" type="primary" onClick={toggleTos}>
                                                Close
                                            </Button>
                                        ]}
                                        centered
                                    >
                                        <TermsOfService />
                                    </Modal>
                                    <Modal
                                        visible={showPp}
                                        width={1000}
                                        onCancel={togglePp}
                                        footer={[
                                            <Button key="back" type="primary" onClick={togglePp}>
                                                Close
                                            </Button>
                                        ]}
                                        centered
                                    >
                                        <PrivacyPolicy />
                                    </Modal>
                                </Fragment>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} xl={24}>
                                <br />
                                <AdvantagesComponent />
                            </Col>
                        </Row>
                    </>
                </Col>
                <Col xs={24} xl={12}>
                    <div className={'spots_remaining_component'}>
                        {' '}
                        <SpotsRemainingComponent />{' '}
                    </div>
                </Col>
            </Row>
            <div className={styles.hr_header_mid_div}>
                <hr className={styles.hr_header_mid}></hr>
            </div>
            <div className="reviews_component">
                <ReviewsComponent />
            </div>
            <hr className={styles.hr_header}></hr>
            <div align="center" className={styles.start_signup_now_button_div}>
                <Button
                    className={styles.start_signup_now_button}
                    type="success"
                    size="large"
                    block
                    htmlType="submit"
                    onClick={handleFocus}
                >
                    START YOUR SIGN-UP NOW
                </Button>
            </div>
            <div style={{marginTop: '1rem'}} className={'sign-up_button'}>
                <SignUpNowButton handleFocus={handleFocus} />
            </div>
            <div>
                <FooterComponent />
            </div>
        </>
    )
}

export default AccountCreationForm
