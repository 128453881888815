import {gql} from '@apollo/client'

export const UPDATE_PERSONAL_DETAILS = gql`
    mutation UpdatePersonalDetails($subscriberId: String!, $params: NewPersonalDetails) {
        updatePersonalDetails(subscriberId: $subscriberId, params: $params) {
            firstName
            lastName
            phoneNumber
            secondaryPhoneNumber
        }
    }
`
