import React from 'react'
import {Button} from 'antd'
import './SignUpNowButton.css'

const SignUpNowButton = ({handleFocus}) => (
    <div align="center">
        <Button
            className={'start_signup_now_button'}
            type="success"
            size="large"
            block
            htmlType="submit"
            onClick={handleFocus}
        >
            SIGN-UP NOW
        </Button>
    </div>
)

export default SignUpNowButton
