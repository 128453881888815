import React from 'react'
import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import Confirmation from './confirm.svg'
import routes from '../../../../routes'
import styles from './Confirmation.module.css'

const ConfirmationPage = () => {
    const history = useHistory()
    useEffect(() => {
        setTimeout(() => {
            history.push(routes.onBoardingPayment)
        }, 3000)
    }, [history])
    return (
        <div data-testid="uploadBill-confirmation" className={styles.confirmation_container}>
            <div className={styles.icon_container}>
                <img alt="" src={Confirmation} />
                <h2 className={styles.confirmation_text}>Great!</h2>
            </div>
            <h3 className={styles.confirmation_message}>Bill has been successfully uploaded</h3>
        </div>
    )
}

export default ConfirmationPage
