import {Divider, Tabs, Typography} from 'antd'
import {useQuery} from '@apollo/client'

import GeneralSettings from './GeneralSettings/GeneralSettings'
import EmailSettings from './EmailSettings/EmailSettings'
import SecuritySettings from './SecuritySettings/SecuritySettings'
import Spinner from '../Common/Spinner/Spinner'

import {GET_SUBSCRIBER} from '../../graphQL/queries/subscriber'
import {CURRENT_USER} from '../../graphQL/queries/user'

import styles from './SettingsPage.module.css'

const {Title} = Typography
const {TabPane} = Tabs

function SettingsPage() {
    const {
        data: {currentUser},
        loading: currentUserLoading
    } = useQuery(CURRENT_USER)

    const {data: subscriberData, loading: subscriberLoading} = useQuery(GET_SUBSCRIBER, {
        skip: !currentUser?.idToken?.payload['custom:id'],
        fetchPolicy: 'network-only',
        variables: {subscriberId: currentUser?.idToken?.payload['custom:id']}
    })

    return (
        <div className={styles.frame}>
            <Title level={4} className={styles.title}>
                Account Settings
            </Title>
            <Divider />
            {currentUserLoading || subscriberLoading ? (
                <Spinner className={styles.spinner} />
            ) : (
                <Tabs defaultActiveKey="1">
                    <TabPane tab="General" key="general">
                        <GeneralSettings
                            userDetails={subscriberData?.subscriber}
                            subscriberId={currentUser?.idToken?.payload['custom:id']}
                        />
                    </TabPane>
                    <TabPane tab="Email" key="email">
                        <EmailSettings userDetails={subscriberData?.subscriber} />
                    </TabPane>
                    <TabPane tab="Security" key="security">
                        <SecuritySettings />
                    </TabPane>
                </Tabs>
            )}
        </div>
    )
}

export default SettingsPage
