import {Form, Input, notification, Typography} from 'antd'
import {Fragment, useState} from 'react'
import {changePassword} from '../../auth/auth'
import RoundButton from '../../Common/RoundButton/RoundButton'
import styles from './SecuritySettings.module.css'

const {Text} = Typography

function SecuritySettings() {
    const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true)
    const [passwordsMatch, setPasswordMatch] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const [changePasswordForm] = Form.useForm()

    function handleValuesChange(changedValues, allValues) {
        const passwordsMatch = allValues.newPassword === allValues.confirmNewPassword
        const enableResetButton =
            allValues.newPassword &&
            allValues.confirmNewPassword &&
            allValues.oldPassword &&
            passwordsMatch
        setPasswordMatch(passwordsMatch)
        setIsResetButtonDisabled(!enableResetButton)
    }

    function handleSubmit(values) {
        setIsLoading(true)
        changePassword(values.oldPassword, values.newPassword, handleChangePasswordResponse)
    }

    function handleChangePasswordResponse(response) {
        setIsLoading(false)
        changePasswordForm.resetFields()
        response.error
            ? notification.error({
                  message: 'Password couldn’t be updated',
                  description: response.error.message
              })
            : notification.success({message: 'Password successfully updated'})
    }

    return (
        <Fragment>
            <label className={styles.detailsTitle}>Change Your Password</label>
            <Form
                form={changePasswordForm}
                onValuesChange={handleValuesChange}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Form.Item
                    label="Old Password"
                    name="oldPassword"
                    rules={[{required: true, message: 'This field is required'}]}
                >
                    <Input.Password placeholder="Old Password" />
                </Form.Item>
                <Form.Item
                    label="New Password"
                    name="newPassword"
                    rules={[{required: true, message: 'This field is required'}]}
                >
                    <Input.Password placeholder="New Password" />
                </Form.Item>
                <Form.Item
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    rules={[{required: true, message: 'This field is required'}]}
                >
                    <Input.Password placeholder="Confirm New Password" />
                </Form.Item>
                <div>{!passwordsMatch && <Text type="danger">Passwords do not match</Text>}</div>
                <RoundButton
                    className={styles.submitButton}
                    type="primary"
                    htmlType="submit"
                    disabled={isResetButtonDisabled || isLoading}
                >
                    Reset Password
                </RoundButton>
            </Form>
        </Fragment>
    )
}

export default SecuritySettings
