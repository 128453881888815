import {Typography, Form, Button, notification} from 'antd'
import {logoWhiteImage} from '../../assets'
import {useState} from 'react'
import Page from '../Common/Page'
import styles from './LoginPage.module.css'
import LoginInput from './LoginInput/LoginInput'
import LoginButton from './LoginButton/LoginButton'
import Spinner from '../Common/Spinner/Spinner'
import SendCodeModal from './SendCodeModal/SendCodeModal'
import {signIn} from '../auth/auth'
import {useHistory} from 'react-router-dom'
import routes from '../../routes'
import {currentUserVar} from '../../graphQL/cache'

const {Title} = Typography

function LoginPage() {
    const [loginForm] = Form.useForm()
    const history = useHistory()

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    function toggleModal(show) {
        setIsModalVisible(show)
    }

    function onSigninSubmit(values) {
        setIsLoading(true)
        signIn(values.username.toLowerCase(), values.password, onSignInResponse)
    }

    function onSignInResponse(response, currentUser, error) {
        setIsLoading(false)
        if (response === 'success') {
            currentUserVar(currentUser)
            history.push(routes.settings)
        } else if (response === 'failure') {
            loginForm.resetFields()
            notification.error({
                message: 'Login unsuccessful',
                description: error
            })
        }
    }

    return (
        <Page>
            <div className={styles.container}>
                <div className={styles.sidebar}>
                    <div className={styles.form}>
                        <Title className={styles.title} level={2}>
                            Login
                        </Title>
                        <p className={styles.caption}>Hello! Let's get started...</p>
                        <Form form={loginForm} name="login-form" onFinish={onSigninSubmit}>
                            <Form.Item name="username" className={styles.formItem}>
                                <LoginInput placeholder="Username" type="text" />
                            </Form.Item>
                            <Form.Item name="password" className={styles.formItem}>
                                <LoginInput placeholder="Password" type="password" />
                            </Form.Item>
                            <LoginButton
                                htmlType="submit"
                                className={styles.loginBtn}
                                type="primary"
                                block
                            >
                                Login
                            </LoginButton>
                            {isLoading && (
                                <Spinner
                                    style={{
                                        color: '#fff'
                                    }}
                                    className={styles.spinner}
                                />
                            )}
                        </Form>
                        <Button
                            onClick={() => toggleModal(true)}
                            type="link"
                            htmlType="button"
                            className={styles.forgotPasswordBtn}
                        >
                            Forgot Password
                        </Button>
                    </div>
                    <div className={styles.credits}>
                        <img
                            className={styles.creditsLogo}
                            src={logoWhiteImage}
                            alt="Save Solar Logo"
                        ></img>
                        <a className={styles.creditsLink} href="/#">
                            www.savesolar.us
                        </a>
                    </div>
                </div>
            </div>
            <SendCodeModal
                visible={isModalVisible}
                footer={null}
                onCancel={() => toggleModal(false)}
            />
        </Page>
    )
}

export default LoginPage
