import { gql } from '@apollo/client'

export const GET_FACILITY_SHOW_LOCAL_FARM = gql`
query facilityShowLocalFarm{
    facilityShowLocalFarm{
        facilityId
        facilityName
        nameplateCapacityAc
        anticipatedAnnualGenAc
        degradationRate
        facilityImageUrl
        siteName
    }
}
`;