import React, {useState, useCallback, Fragment} from 'react'
import {Button} from 'antd'
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack'
import {useHistory} from 'react-router-dom'
import routes from '../../../../routes'

import {PlusCircleOutlined, CloseCircleFilled} from '@ant-design/icons'
import styles from './BillModalUploadFiles.module.css'
import {callUploadSubscriberBills} from '../../../api/call-api-axios'

import Upload from './upload.svg'

const BillModalUploadFiles = (props) => {
    const history = useHistory()
    const [fileArray, setFileArray] = useState([])
    const [count, setCount] = useState(0)
    const [isUploading, setIsUploading] = useState(false)
    const [saveIsVisible, setSaveIsVisible] = useState(false)
    const [errorMessages, setErrorMessages] = useState()
    const [successMessages, setSuccessMessages] = useState()
    const [totalSizeFile, setTotalSizeFile] = useState(0)

    const resetStates = useCallback(() => {
        localStorage.clear()
        const queryParams = new URLSearchParams(window.location.search)
        const utmParameters = {
            utmCampaign: queryParams.get('utm_campaign'),
            utmSource: queryParams.get('utm_source'),
            utmMedium: queryParams.get('utm_medium'),
            timeClicked: new Date(),
            zipCode: queryParams.get('zip_code'),
            firstName: queryParams.get('first_name') || '',
            lastName: queryParams.get('last_name') || '',
            email: queryParams.get('email') || '',
            phoneNumber: queryParams.get('phone') || ''
        }
        localStorage.setItem('utmParameters', JSON.stringify(utmParameters))
    }, [])

    const handleChange = (e) => {
        setSuccessMessages()
        if (e.target.files[0]) {
            let fileObj = e.target.files[0]
            setErrorMessages()
            if (
                props.allowedExtensions.indexOf(fileObj.name.split('.').pop().toLowerCase()) === -1
            ) {
                setErrorMessages(
                    'Invalid extension for file ' + fileObj.name + '. Please try another file.'
                )
            } else if (fileObj.size > props.fileMaxLength) {
                setErrorMessages(
                    'The maximum size allowed of all files is 4MB. ' +
                        fileObj.name +
                        '. Please try another file.'
                )
            } else if (totalSizeFile + fileObj.size > props.fileMaxLength) {
                setErrorMessages('You have reached the maximum size allowed. ' + fileObj.name + '.')
            } else if (count > props.maxNumberFiles - 1) {
                setErrorMessages(
                    'You have reached the maximum number of files allowed. ' + fileObj.name + '.'
                )
            } else {
                if (fileObj) {
                    const fileArrayData = [...fileArray, e.target.files[0]]
                    setFileArray(fileArrayData)
                    setTotalSizeFile(totalSizeFile + fileObj.size)
                    setSaveIsVisible(true)
                    setCount(fileArrayData.length)
                    if (count === props.maxNumberFiles - 1) {
                        setSuccessMessages('You have uploaded the maximum number of files allowed')
                    }
                }
                setErrorMessages()
            }
        }
    }

    const handleUpload = async () => {
        try {
            setIsUploading(true)
            const response = await callUploadSubscriberBills(fileArray)
            if (response.status === 200) {
                props.onConfirm()
            }
        } catch (error) {
            if (error.message === 'Token is not valid') {
                console.error(error)
                resetStates()
                history.push(routes.onBoardingSignup)
            } else {
                console.error(error)
            }
        }
    }

    const handleDelete = (fileObj, index) => {
        const fileArrayUpdated = [...fileArray]
        fileArrayUpdated.splice(index, 1)
        setFileArray(fileArrayUpdated)
        setTotalSizeFile(totalSizeFile - fileObj.size)
        setCount(fileArrayUpdated.length)
        if (!fileArrayUpdated.length) setSaveIsVisible(false)
    }

    return (
        <div>
            {!isUploading ? (
                <>
                    <div className={styles.container}>
                        {errorMessages && (
                            <div className={styles.text_error} data-testid="errorMsgFiles">
                                <p>{errorMessages}</p>
                            </div>
                        )}
                        {successMessages && (
                            <div className={styles.text_green}>
                                <p>{successMessages}</p>
                            </div>
                        )}
                    </div>
                    <div className={styles.container}>
                        {saveIsVisible ? (
                            <Button
                                onClick={handleUpload}
                                type="primary"
                                size="large"
                                className={styles.save_button}
                                data-testid="uploadBill-saveButton"
                            >
                                Save
                            </Button>
                        ) : null}
                        <div className={styles.images_container}>
                            <input
                                type="file"
                                id="files"
                                onChange={handleChange}
                                style={{display: 'none'}}
                                multiple
                            />
                            <label
                                htmlFor="files"
                                className={count === 0 ? styles.buttonInitial : styles.button}
                            >
                                <PlusCircleOutlined className={styles.select} />
                                {count === 0 ? 'Add files' : 'Add more files'}
                            </label>
                            {fileArray.map((file, index) => {
                                const fileSubstr = file.name
                                const pdfExtension = fileSubstr.substring(fileSubstr.length - 3)
                                if (pdfExtension === 'pdf') {
                                    return (
                                        <Fragment key={index}>
                                            <div>
                                                <div className={styles.close_pdf_container}>
                                                    <CloseCircleFilled
                                                        onClick={() => handleDelete(file, index)}
                                                        className={styles.close_circle_icon}
                                                        data-testid="closePdfButton"
                                                    />
                                                </div>
                                                <Document file={file}>
                                                    <Page
                                                        pageNumber={1}
                                                        height={60}
                                                        width={50}
                                                        className={styles.image_pdf}
                                                    />
                                                </Document>
                                            </div>
                                        </Fragment>
                                    )
                                } else {
                                    return (
                                        <div key={index}>
                                            <div className={styles.close_img_container}>
                                                <CloseCircleFilled
                                                    data-testid="closeImgButton"
                                                    onClick={() => handleDelete(file, index)}
                                                    className={styles.close_circle_icon}
                                                />
                                            </div>
                                            <img
                                                alt=""
                                                src={URL.createObjectURL(file)}
                                                className={styles.image}
                                            />
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </>
            ) : (
                <div className={styles.upload_message} data-testid="uploadBill-uploadingView">
                    <img alt="" src={Upload} />
                    <h3 className={styles.uploading}>Uploading...</h3>
                </div>
            )}
        </div>
    )
}

export default BillModalUploadFiles
