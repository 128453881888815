import React, {useState} from 'react'

const OnboardingPageContext = React.createContext()

export const OnboardingPageContextProvider = (props) => {
    const [subscriber, setSubscriber] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        tos: false
    })
    const {children} = props
    return (
        <OnboardingPageContext.Provider
            value={{
                subscriber,
                setSubscriber
            }}
        >
            {children}
        </OnboardingPageContext.Provider>
    )
}

export default OnboardingPageContext
