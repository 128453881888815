import React from 'react'
import {Row, Col} from 'antd'
import styles from './Common.module.css'

const SuccessStoriesComponent = ({userPhoto, userName, farmLocation, userStory, star}) => (
    <div className={styles.success_story_box}>
        <Row>
            <Col xs={24} xl={24}>
                <div align="center">
                    <img alt={userName} src={userPhoto} className={styles.user_photo} />
                </div>
                <div className={styles.star_box}>
                    <img alt="Qualification Star" src={star} className={styles.star} />
                </div>
                <div align="center">
                    <h2 className={styles.username} style={{marginBottom: '0px'}}>
                        <b>{userName}</b>
                    </h2>
                    <h3 className={styles.farm_location} style={{marginBottom: '15px'}}>
                        {farmLocation}
                    </h3>
                </div>
                <div align="center">
                    <h4 className={styles.user_story}>{userStory}</h4>
                </div>
            </Col>
        </Row>
    </div>
)

export default SuccessStoriesComponent
