import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div>
            <h1
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '40px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                PRIVACY POLICY
            </h1>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Last updated December 05, 2018&nbsp;
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Thank you for choosing to be part of our community at&nbsp;SaveSolar
                Corporation&nbsp;(“
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Company
                </strong>
                ”, “
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    we
                </strong>
                ”, “
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    us
                </strong>
                ”, or “
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    our
                </strong>
                ”). We are committed to protecting your personal information and your right to
                privacy. If you have any questions or concerns about our policy, or our practices
                with regards to your personal information, please contact us
                atadmin@savesolarcorporation.com.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                When you visit our&nbsp;website&nbsp;https://www.savesolar.us/,&nbsp;and use our
                services, you trust us with your personal information. We take your privacy very
                seriously. In this privacy notice, we describe our privacy policy. We seek to
                explain to you in the clearest way possible what information we collect, how we use
                it and what rights you have in relation to it. We hope you take some time to read
                through it carefully, as it is important. If there are any terms in this privacy
                policy that you do not agree with, please discontinue use of our&nbsp;Sites&nbsp;and
                our services.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                This privacy policy applies to all information collected through
                our&nbsp;website&nbsp;(such as&nbsp;https://www.savesolar.us/),and/or any related
                services, sales, marketing or events (we refer to them collectively in this privacy
                policy as the “
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Sites
                </strong>
                “).
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Please read this privacy policy carefully as it will help you make informed
                    decisions about sharing your personal information with us.&nbsp;
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    TABLE OF CONTENTS
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
            </p>
            <ol
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={0}
                        href="https://www.savesolar.us/privacy-policy/#1"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            WHAT INFORMATION DO WE COLLECT?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={1}
                        href="https://www.savesolar.us/privacy-policy/#2"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            HOW DO WE USE YOUR INFORMATION?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={2}
                        href="https://www.savesolar.us/privacy-policy/#3"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={3}
                        href="https://www.savesolar.us/privacy-policy/#4"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            WHO WILL YOUR INFORMATION BE SHARED WITH?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={4}
                        href="https://www.savesolar.us/privacy-policy/#5"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={5}
                        href="https://www.savesolar.us/privacy-policy/#6"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            DO WE USE GOOGLE MAPS?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={6}
                        href="https://www.savesolar.us/privacy-policy/#7"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={7}
                        href="https://www.savesolar.us/privacy-policy/#8"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            HOW LONG DO WE KEEP YOUR INFORMATION?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={8}
                        href="https://www.savesolar.us/privacy-policy/#9"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            HOW DO WE KEEP YOUR INFORMATION SAFE?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={9}
                        href="https://www.savesolar.us/privacy-policy/#10"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            DO WE COLLECT INFORMATION FROM MINORS?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={10}
                        href="https://www.savesolar.us/privacy-policy/#11"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            WHAT ARE YOUR PRIVACY RIGHTS?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={11}
                        href="https://www.savesolar.us/privacy-policy/#12"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            CONTROLS FOR DO-NOT-TRACK FEATURES
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={12}
                        href="https://www.savesolar.us/privacy-policy/#13"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={13}
                        href="https://www.savesolar.us/privacy-policy/#14"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            DO WE MAKE UPDATES TO THIS POLICY?
                        </span>
                    </a>
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={14}
                        href="https://www.savesolar.us/privacy-policy/#15"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        <span
                            style={{
                                WebkitFontSmoothing: 'antialiased',
                                margin: '0px',
                                padding: '0px',
                                border: '0px rgb(226, 226, 226)',
                                font: 'inherit',
                                verticalAlign: 'baseline',
                                color: 'inherit',
                                boxSizing: 'inherit',
                                outline: '0px',
                                background: 'transparent'
                            }}
                        >
                            HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                        </span>
                    </a>
                </li>
            </ol>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    1. WHAT INFORMATION DO WE COLLECT?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Personal information you disclose to us
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    We collect personal information that you provide to us such as name, address,
                    contact information, passwords and security data,&nbsp;payment information, and
                    social media login data
                </em>
                .
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We collect personal information that you voluntarily provide to us
                when&nbsp;expressing an interest in obtaining information about us or our products
                and services, when participating in activities on the&nbsp;Sites&nbsp;or otherwise
                contacting us.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                The personal information that we collect depends on the context of your interactions
                with us and the&nbsp;Sites, the choices you make and the products and features you
                use. The personal information we collect can include the following:
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Name and Contact Data.
                </strong>{' '}
                We collect your first and last name, email address, postal address, phone number,
                and other similar contact data.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Credentials.
                </strong>{' '}
                We collect passwords, password hints, and similar security information used for
                authentication and account access.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Payment Data.
                </strong>{' '}
                We collect data necessary to process your payment if you make purchases, such as
                your payment instrument number (such as a credit card number), and the security code
                associated with your payment instrument. All payment data is stored by our payment
                processor and you should review its privacy policies and contact the payment
                processor directly to respond to your questions.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Social Media Login Data.
                </strong>{' '}
                We provide you with the option to register using social media account details, like
                your Facebook, Twitter or other social media account. If you choose to register in
                this way, we will collect the Information described in the section called “
                <a
                    aria-label="Link"
                    data-auth="NotApplicable"
                    data-linkindex={15}
                    href="https://app.termly.io/document/privacy-policy/a6f3aa7d-abb6-40d6-b1e5-f06b009c67d9#sociallogins/#1"
                    rel="noopener noreferrer"
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        margin: '0px',
                        padding: '0px',
                        border: '0px rgb(226, 226, 226)',
                        font: 'inherit',
                        verticalAlign: 'baseline',
                        boxSizing: 'inherit',
                        outline: '0px',
                        background: 'transparent',
                        textDecorationLine: 'none',
                        color: 'rgb(116, 116, 116)'
                    }}
                    target="_blank"
                >
                    HOW DO WE HANDLE YOUR SOCIAL LOGINS
                </a>{' '}
                ” below.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                All personal information that you provide to us must be true, complete and accurate,
                and you must notify us of any changes to such personal information.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Information automatically collected
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Some information – such as IP address and/or browser and device characteristics
                    – is collected automatically when you visit our&nbsp;Sites.
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We automatically collect certain information when you visit, use or navigate
                the&nbsp;Sites. This information does not reveal your specific identity (like your
                name or contact information) but may include device and usage information, such as
                your IP address, browser and device characteristics, operating system, language
                preferences, referring URLs, device name, country, location, information about how
                and when you use our&nbsp;Sites&nbsp;and other technical information. This
                information is primarily needed to maintain the security and operation of
                our&nbsp;Sites, and for our internal analytics and reporting purposes.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Like many businesses, we also collect information through cookies and similar
                technologies.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Information collected from other sources
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    We may collect limited data from public databases, marketing partners, social
                    media platforms, and other outside sources.&nbsp;
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We may obtain information about you from other sources, such as public databases,
                joint marketing partners,social media platforms (such as Facebook),&nbsp;as well as
                from other third parties. Examples of the information we receive from other sources
                include: social media profile information&nbsp;(your name, gender, birthday, email,
                current city, state and country, user identification numbers for your contacts,
                profile picture URL and any other information that you choose to make public);
                marketing leads and search results and links, including paid listings (such as
                sponsored links).
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    2.
                </strong>{' '}
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    HOW DO WE USE YOUR INFORMATION?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    We process your information for purposes based on legitimate business interests,
                    the fulfillment of our contract with you, compliance with our legal obligations,
                    and/or your consent.
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We use personal information collected via our&nbsp;Sites&nbsp;for a variety of
                business purposes described below. We process your personal information for these
                purposes in reliance on our legitimate business interests, in order to enter into or
                perform a contract with you, with your consent, and/or for compliance with our legal
                obligations. We indicate the specific processing grounds we rely on next to each
                purpose listed below.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We use the information we collect or receive:
            </p>
            <ul
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        To facilitate account creation and logon process.
                    </strong>{' '}
                    If you choose to link your account with us to a third party account *(such as
                    your Google or Facebook account), we use the information you allowed us to
                    collect from those third parties to facilitate account creation and logon
                    process. See the section below headed ”{' '}
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={16}
                        href="https://app.termly.io/document/privacy-policy/a6f3aa7d-abb6-40d6-b1e5-f06b009c67d9#sociallogins"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        HOW DO WE HANDLE YOUR SOCIAL LOGINS
                    </a>{' '}
                    ” for further information.
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        To send you marketing and promotional communications.
                    </strong>{' '}
                    We and/or our third party marketing partners may use the personal information
                    you send to us for our marketing purposes, if this is in accordance with your
                    marketing preferences. You can opt-out of our marketing emails at any time (see
                    the ”{' '}
                    <a
                        aria-label="Link"
                        data-auth="NotApplicable"
                        data-linkindex={17}
                        href="https://app.termly.io/document/privacy-policy/a6f3aa7d-abb6-40d6-b1e5-f06b009c67d9#privacyrights"
                        rel="noopener noreferrer"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            margin: '0px',
                            padding: '0px',
                            border: '0px rgb(226, 226, 226)',
                            font: 'inherit',
                            verticalAlign: 'baseline',
                            boxSizing: 'inherit',
                            outline: '0px',
                            background: 'transparent',
                            textDecorationLine: 'none',
                            color: 'rgb(116, 116, 116)'
                        }}
                        target="_blank"
                    >
                        WHAT ARE YOUR PRIVACY RIGHTS
                    </a>{' '}
                    ” below).
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        To send administrative information to you.&nbsp;
                    </strong>
                    We may use your personal information to send you product, service and new
                    feature information and/or information about changes to our terms, conditions,
                    and policies.
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Deliver targeted advertising to you.&nbsp;
                    </strong>
                    We may use your information to develop and display content and advertising (and
                    work with third parties who do so) tailored to your interests and/or location
                    and to measure its effectiveness.
                </li>
            </ul>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    We only share information with your consent, to comply with laws, to protect
                    your rights, or to fulfill business obligations.&nbsp;
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We may process or share data based on the following legal basis:
            </p>
            <ul
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Consent:
                    </strong>{' '}
                    We may process your data if you have given us specific consent to use your
                    personal information in a specific purpose.
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Legitimate Interests:
                    </strong>{' '}
                    We may process your data when it is reasonably necessary to achieve our
                    legitimate business interests.
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Performance of a Contract:&nbsp;
                    </strong>
                    Where we have entered into a contract with you, we may process your personal
                    information to fulfill the terms of our contract.
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Legal Obligations:
                    </strong>{' '}
                    We may disclose your information where we are legally required to do so in order
                    to comply with applicable law, governmental requests, a judicial proceeding,
                    court order, or legal process, such as in response to a court order or a
                    subpoena (including in response to public authorities to meet national security
                    or law enforcement requirements).
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Vital Interests:
                    </strong>{' '}
                    We may disclose your information where we believe it is necessary to
                    investigate, prevent, or take action regarding potential violations of our
                    policies, suspected fraud, situations involving potential threats to the safety
                    of any person and illegal activities, or as evidence in litigation in which we
                    are involved.
                </li>
            </ul>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                More specifically, we may need to process your data or share your personal
                information in the following situations:
            </p>
            <ul
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Vendors, Consultants and Other Third-Party Service Providers.
                    </strong>{' '}
                    We may share your data with third party vendors, service providers, contractors
                    or agents who perform services for us or on our behalf and require access to
                    such information to do that work. Examples include: payment processing, data
                    analysis, email delivery, hosting services, customer service and marketing
                    efforts. We may allow selected third parties to use tracking technology on the
                    Sites, which will enable them to collect data about how you interact with
                    theSites over time. This information may be used to, among other things, analyze
                    and track data, determine the popularity of certain content and better
                    understand online activity. Unless described in this Policy, we do not share,
                    sell, rent or trade any of your information with third parties for their
                    promotional purposes.
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Business Transfers.
                    </strong>{' '}
                    We may share or transfer your information in connection with, or during
                    negotiations of, any merger, sale of company assets, financing, or acquisition
                    of all or a portion of our business to another company.
                </li>
            </ul>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    4. WHO WILL YOUR INFORMATION BE SHARED WITH?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    We only share information with the following third parties.
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We only share and disclose your information with the following third parties. We
                have categorized each party so that you may be easily understand the purpose of our
                data collection and processing practices. If we have processed your data based on
                your consent and you wish to revoke your consent, please contact us.
            </p>
            <ul
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Advertising, Direct Marketing, and Lead Generation
                    </strong>
                    <br
                        aria-hidden="true"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            borderColor: 'rgb(226, 226, 226)'
                        }}
                    />
                    HubSpot Lead Management&nbsp;and&nbsp;HubSpot CRM
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Allow Users to Connect to their Third-Party Accounts
                    </strong>
                    <br
                        aria-hidden="true"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            borderColor: 'rgb(226, 226, 226)'
                        }}
                    />
                    ,&nbsp;Facebook account&nbsp;and&nbsp;Google account
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Communicate and Chat with Users
                    </strong>
                    <br
                        aria-hidden="true"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            borderColor: 'rgb(226, 226, 226)'
                        }}
                    />
                    HubSpot Email
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Content Optimization&nbsp;
                    </strong>
                    <br
                        aria-hidden="true"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            borderColor: 'rgb(226, 226, 226)'
                        }}
                    />
                    Google Site Search
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Data Backup and Security
                    </strong>
                    <br
                        aria-hidden="true"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            borderColor: 'rgb(226, 226, 226)'
                        }}
                    />
                    Google Drive Backup
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Retargeting Platforms
                    </strong>
                    <br
                        aria-hidden="true"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            borderColor: 'rgb(226, 226, 226)'
                        }}
                    />
                    Google Analytics Remarketing
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Social Media Sharing and Advertising
                    </strong>
                    <br
                        aria-hidden="true"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            borderColor: 'rgb(226, 226, 226)'
                        }}
                    />
                    Facebook advertising&nbsp;and&nbsp;Facebook social plugins
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Web and Mobile Analytics
                    </strong>
                    <br
                        aria-hidden="true"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            borderColor: 'rgb(226, 226, 226)'
                        }}
                    />
                    Google Analytics
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <strong
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        Website Hosting
                    </strong>
                    <br
                        aria-hidden="true"
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            borderColor: 'rgb(226, 226, 226)'
                        }}
                    />
                    WordPress.com
                </li>
            </ul>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    We may use cookies and other tracking technologies to collect and store your
                    information.
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We may use cookies and similar tracking technologies (like web beacons and pixels)
                to access or store information. Specific information about how we use such
                technologies and how you can refuse certain cookies is set out in our Cookie Policy.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    6. DO WE USE GOOGLE MAPS?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Yes, we use Google Maps for the purpose of providing better service.
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                This website, mobile application, or Facebook application uses Google Maps APIs. You
                may find the Google Maps APIs Terms of Service{' '}
                <a
                    aria-label="Link"
                    data-auth="NotApplicable"
                    data-linkindex={18}
                    href="https://developers.google.com/maps/terms"
                    rel="noopener noreferrer"
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        margin: '0px',
                        padding: '0px',
                        border: '0px rgb(226, 226, 226)',
                        font: 'inherit',
                        verticalAlign: 'baseline',
                        boxSizing: 'inherit',
                        outline: '0px',
                        background: 'transparent',
                        textDecorationLine: 'none',
                        color: 'rgb(116, 116, 116)'
                    }}
                    target="_blank"
                >
                    reading this article
                </a>
                . To better understand Google’s Privacy Policy, please refer to this{' '}
                <a
                    aria-label="Link"
                    data-auth="NotApplicable"
                    data-linkindex={19}
                    href="https://policies.google.com/privacy"
                    rel="noopener noreferrer"
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        margin: '0px',
                        padding: '0px',
                        border: '0px rgb(226, 226, 226)',
                        font: 'inherit',
                        verticalAlign: 'baseline',
                        boxSizing: 'inherit',
                        outline: '0px',
                        background: 'transparent',
                        textDecorationLine: 'none',
                        color: 'rgb(116, 116, 116)'
                    }}
                    target="_blank"
                >
                    article
                </a>
                .
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                By using our Maps API Implementation, you agree to be bound by Google’s Terms of
                Service.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    If you choose to register or log in to our websites using a social media
                    account, we may have access to certain information about you.
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Our&nbsp;Sites&nbsp;offer you the ability to register and login using your third
                party social media account details (like your Facebook or Twitter logins). Where you
                choose to do this, we will receive certain profile information about you from your
                social media provider. The profile Information we receive may vary depending on the
                social media provider concerned, but will often include your name, e-mail address,
                friends list, profile picture as well as other information you choose to make
                public.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We will use the information we receive only for the purposes that are described in
                this privacy policy or that are otherwise made clear to you on the&nbsp;Sites.
                Please note that we do not control, and are not responsible for, other uses of your
                personal information by your third party social media provider. We recommend that
                you review their privacy policy to understand how they collect, use and share your
                personal information, and how you can set your privacy preferences on their sites
                and apps.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    8. HOW LONG DO WE KEEP YOUR INFORMATION?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    We keep your information for as long as necessary to fulfill the purposes
                    outlined in this privacy policy unless otherwise required by law.
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We will only keep your personal information for as long as it is necessary for the
                purposes set out in this privacy policy, unless a longer retention period is
                required or permitted by law (such as tax, accounting or other legal requirements).
                No purpose in this policy will require us keeping your personal information for
                longer than&nbsp;1 year.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                When we have no ongoing legitimate business need to process your personal
                information, we will either delete or anonymize it, or, if this is not possible (for
                example, because your personal information has been stored in backup archives), then
                we will securely store your personal information and isolate it from any further
                processing until deletion is possible.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    9. HOW DO WE KEEP YOUR INFORMATION SAFE?&nbsp;
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    We aim to protect your personal information through a system of organizational
                    and technical security measures.
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We have implemented appropriate technical and organizational security measures
                designed to protect the security of any personal information we process. However,
                please also remember that we cannot guarantee that the internet itself is 100%
                secure. Although we will do our best to protect your personal information,
                transmission of personal information to and from our&nbsp;Sites&nbsp;is at your own
                risk. You should only access the services within a secure environment.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    10. DO WE COLLECT INFORMATION FROM MINORS?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    We do not knowingly collect data from or market to children under 18 years of
                    age.
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We do not knowingly solicit data from or market to children under 18 years of age.
                By using the&nbsp;Sites, you represent that you are at least 18 or that you are the
                parent or guardian of such a minor and consent to such minor dependent’s use of
                the&nbsp;Sites. If we learn that personal information from users less than 18 years
                of age has been collected, we will deactivate the account and take reasonable
                measures to promptly delete such data from our records. If you become aware of any
                data we have collected from children under age 18, please contact us
                atadmin@savesolarcorporation.com.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    11. WHAT ARE YOUR PRIVACY RIGHTS?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    You may review, change, or terminate your account at any time.
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                If you are resident in the European Economic Area and you believe we are unlawfully
                processing your personal information, you also have the right to complain to your
                local data protection supervisory authority. You can find their contact details
                here:{' '}
                <a
                    aria-label="Link"
                    data-auth="NotApplicable"
                    data-linkindex={20}
                    href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                    rel="noopener noreferrer"
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        margin: '0px',
                        padding: '0px',
                        border: '0px rgb(226, 226, 226)',
                        font: 'inherit',
                        verticalAlign: 'baseline',
                        boxSizing: 'inherit',
                        outline: '0px',
                        background: 'transparent',
                        textDecorationLine: 'none',
                        color: 'rgb(116, 116, 116)'
                    }}
                    target="_blank"
                >
                    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                </a>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    12. CONTROLS FOR DO-NOT-TRACK FEATURES
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Most web browsers and some mobile operating systems and mobile applications include
                a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy
                preference not to have data about your online browsing activities monitored and
                collected. No uniform technology standard for recognizing and implementing DNT
                signals has been finalized. As such, we do not currently respond to DNT browser
                signals or any other mechanism that automatically communicates your choice not to be
                tracked online. If a standard for online tracking is adopted that we must follow in
                the future, we will inform you about that practice in a revised version of this
                Privacy Policy.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Yes, if you are a resident of California, you are granted specific rights
                    regarding access to your personal information.&nbsp;
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                California Civil Code Section 1798.83, also known as the “Shine The Light” law,
                permits our users who are California residents to request and obtain from us, once a
                year and free of charge, information about categories of personal information (if
                any) we disclosed to third parties for direct marketing purposes and the names and
                addresses of all third parties with which we shared personal information in the
                immediately preceding calendar year. If you are a California resident and would like
                to make such a request, please submit your request in writing to us using the
                contact information provided below.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                If you are under 18 years of age, reside in California, and have a registered
                account with the&nbsp;Sites, you have the right to request removal of unwanted data
                that you publicly post on the&nbsp;Sites. To request removal of such data, please
                contact us using the contact information provided below, and include the email
                address associated with your account and a statement that you reside in California.
                We will make sure the data is not publicly displayed on the&nbsp;Sites, but please
                be aware that the data may not be completely or comprehensively removed from our
                systems.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    14. DO WE MAKE UPDATES TO THIS POLICY?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        In Short:
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    <em
                        style={{
                            WebkitFontSmoothing: 'antialiased',
                            boxSizing: 'inherit',
                            border: '0px rgb(226, 226, 226)',
                            outline: '0px',
                            verticalAlign: 'baseline',
                            background: 'transparent',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        &nbsp;
                    </em>
                </strong>
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    &nbsp;
                </strong>
                <em
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Yes, we will update this policy as necessary to stay compliant with relevant
                    laws.
                </em>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We may update this privacy policy from time to time. The updated version will be
                indicated by an updated “Revised” date and the updated version will be effective as
                soon as it is accessible. If we make material changes to this privacy policy, we may
                notify you either by prominently posting a notice of such changes or by directly
                sending you a notification. We encourage you to review this privacy policy
                frequently to be informed of how we are protecting your information.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                If you have questions or comments about this policy, you may&nbsp;email us
                at&nbsp;admin@savesolarcorporation.com&nbsp;or by post to:
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                SaveSolar Corporation
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                810 7th St NE
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Washington, D.C.,&nbsp;DC&nbsp;20002
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                United States
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                If you have any further questions or comments about us or our policies, email us
                atadmin@savesolarcorporation.com&nbsp;or by post to:
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                SaveSolar Corporation
                <br
                    aria-hidden="true"
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        borderColor: 'rgb(226, 226, 226)'
                    }}
                />
                810 7th St NE
                <br
                    aria-hidden="true"
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        borderColor: 'rgb(226, 226, 226)'
                    }}
                />
                Washington, D.C.&nbsp;,&nbsp;DC&nbsp;20002
                <br
                    aria-hidden="true"
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        borderColor: 'rgb(226, 226, 226)'
                    }}
                />
                United States
                <br
                    aria-hidden="true"
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        borderColor: 'rgb(226, 226, 226)'
                    }}
                />
                Phone: (202) 846 6928
            </p>
        </div>
    )
}

export default PrivacyPolicy
