import {Button} from 'antd'
import classNames from 'classnames'

import styles from './LoginButton.module.css'

function LoginButton({children, className, ...props}) {
    const computedClassName = classNames(className, styles.loginButton)
    return (
        <Button size="large" className={computedClassName} {...props}>
            {children}
        </Button>
    )
}

export default LoginButton
