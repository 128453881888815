const AmazonCognitoIdentity = require('amazon-cognito-identity-js')
const poolIds = require(`./sync-backend-auth/user-pool-ids.js`)
// const adminPoolIds = require(`./sync-backend-admin-auth/user-pool-ids.js`);

const AWS = require('aws-sdk')
AWS.config.update({region: 'us-east-1'})

const poolData = {
    UserPoolId: poolIds.poolId,
    ClientId: poolIds.poolClientId
}

/* const adminPoolData = {
    UserPoolId: adminPoolIds.poolId,
    ClientId: adminPoolIds.poolClientId
}; */

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
// const adminUserPool = new AmazonCognitoIdentity.CognitoUserPool(adminPoolData);

function authDetails(username, password) {
    const authenticationData = {
        Username: username,
        Password: password
    }

    return new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)
}

function signIn(username, password, signinCb, adminPool = false) {
    const authenticationDetails = authDetails(username, password)

    // const Pool = adminPool ? adminUserPool : userPool;
    const Pool = userPool
    const userData = {
        Username: username,
        Pool
    }

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    return cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
            signinCb('success', result, null)
        },
        onFailure: function (error) {
            signinCb('failure', null, error.message)
        }
    })
}

function sendCode(username, cb, adminPool = false) {
    const Pool = userPool
    const userData = {
        Username: username,
        Pool
    }

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    cognitoUser.forgotPassword({
        onSuccess: function (data) {
            cb('success')
        },
        onFailure: function (err) {
            cb('failure')
        }
    })
}

function resetPassword(username, code, password, resetPasswordCb, adminPool = false) {
    const Pool = userPool
    const userData = {
        Username: username,
        Pool
    }

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    cognitoUser.confirmPassword(code, password, {
        onSuccess() {
            const authenticationDetails = authDetails(username, password)

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (currentUser) {
                    resetPasswordCb('success', currentUser, null)
                },
                onFailure: function (error) {
                    resetPasswordCb('failure', null, error.message)
                }
            })
        },
        onFailure(error) {
            resetPasswordCb('failure', null, error.message)
        }
    })
}

function signUp(state, resetFields, updateCurrentUser, confirmSignup, successPush, setLoading) {
    const attributeList = []

    const dataEmail = {
        Name: 'email',
        Value: state.email
    }

    const dataSubscriberId = {
        Name: 'custom:id',
        Value: state.subscriberId
    }

    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail)

    const attributeSubscriberId = new AmazonCognitoIdentity.CognitoUserAttribute(dataSubscriberId)

    attributeList.push(attributeEmail)
    attributeList.push(attributeSubscriberId)

    userPool.signUp(state.email, state.setPassword, attributeList, null, function (err, result) {
        if (err) {
            alert(err.message || JSON.stringify(err))
            setLoading(false)
            resetFields(err.message)
            return
        }
        const cognitoUser = result.user
        const authenticationDetails = authDetails(cognitoUser.getUsername(), state.setPassword)

        return cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                // If signUp is successful, call API to mark user auth_configured = true, new_subscriber = false
                confirmSignup(state.subscriberId)

                if (result.isValid()) {
                    setLoading(false)
                    updateCurrentUser(result)
                    successPush()
                } else {
                    setLoading(false)
                    alert('Session is not valid')
                }
            },
            onFailure: function (err) {
                setLoading(false)
                resetFields(err.message)
                console.error(err.message || JSON.stringify(err))
            }
        })
    })
}

function getSession(updateCurrentUser, currentUser, resolveCb, adminPool = false) {
    let cognitoUser
    if (adminPool) {
        // cognitoUser = adminUserPool.getCurrentUser();
    } else {
        cognitoUser = userPool.getCurrentUser()
    }

    if (cognitoUser != null) {
        //return value of cognitoUser.getSession() - awaited in requireAuth lifecycle method:
        cognitoUser.getSession(function (error, session) {
            if (error) {
                console.error(error.message || JSON.stringify(error))
                resolveCb(false)
            } else {
                if (session.isValid()) {
                    const sessionJwt = session.accessToken.jwtToken
                    const storedJwt = currentUser.accessToken && currentUser.accessToken.jwtToken

                    //This is to avoid unnecessary redux store updates:
                    if (sessionJwt !== storedJwt) {
                        //session in localStorage does not match session in Redux
                        //This will cause Redux re-render, this function will re-run and miss this on second pass
                        //preventing infinite loop and eventual React error for depth exceeded:
                        updateCurrentUser(session)
                    }
                    resolveCb(true) //localStorage and Redux are synced and valid - access granted
                } else {
                    //There is a user session in localStorage, but it is invalid:
                    alert('Session is not valid')
                    resolveCb(false)
                }
            }
        })
    } else {
        //No user session in localStorage - return false:
        resolveCb(false)
    }
}

function getIdToken(resolveCb, currentApp) {
    let cognitoUser = null
    if (currentApp === 'admin') {
        // cognitoUser = adminUserPool.getCurrentUser();
    } else if (currentApp === 'subscriber') {
        cognitoUser = userPool.getCurrentUser()
    }

    if (cognitoUser != null) {
        //leaving non-strict equality because this is how AWS example was.

        return cognitoUser.getSession(function (error, session) {
            if (error) {
                console.error(error.message || JSON.stringify(error))
                resolveCb(null)
            } else {
                if (session.isValid()) {
                    resolveCb(session.idToken.jwtToken)
                } else {
                    console.error('getIdToken(): Session is invalid')
                    resolveCb(null)
                }
            }
        })
    } else {
        resolveCb(null)
    }
}

function changePassword(oldPassword, newPassword, cb) {
    const cognitoUser = userPool.getCurrentUser()

    cognitoUser.getSession(function (err, session) {
        if (err) {
            alert(err)
            return
        }
    })

    cognitoUser.changePassword(oldPassword, newPassword, function (error, result) {
        if (error) {
            cb({error})
        }

        const authenticationDetails = authDetails(cognitoUser.getUsername(), newPassword)
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (authResult) {
                if (authResult.isValid()) {
                    cb({result})
                } else {
                    console.error('"result" is invalid after password change.')
                }
            },
            onFailure: function (err) {
                console.error(err.message || JSON.stringify(err))
            }
        })
    })
}

function signOut(adminPool = false) {
    const cognitoUser = userPool.getCurrentUser()

    if (cognitoUser != null) {
        //Remove session from localStorage:
        cognitoUser.signOut()
    }
}

module.exports = {
    signIn,
    getSession,
    getIdToken,
    signOut,
    signUp,
    changePassword,
    sendCode,
    resetPassword
}
