const jwt = require('jsonwebtoken')

function isNotExpired(exp) {
    const nowSeconds = Math.floor(Date.now() / 1000)
    return nowSeconds < exp
}

function tokenIsSane(token) {
    const decoded = jwt.decode(token)
    const validSubscriberId = decoded?.data?.subscriberId?.length === 36
    const validAudience = decoded?.aud === 'pepco-account-integration'
    const validIssuer = decoded?.iss === 'SavesolarCorporation'
    const notExpired = typeof decoded?.exp === 'number' && isNotExpired(decoded.exp)
    return validSubscriberId && validAudience && validIssuer && notExpired
}

module.exports = {tokenIsSane}
