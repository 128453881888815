import {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {Row, Col} from 'antd'

import styles from './PaymentForm.module.css'
import './PaymentForm.responsive.css'

import HeaderComponent from '../CommonComponents/HeaderComponent'
import ReviewsComponent from '../CommonComponents/ReviewsComponent'
import SpotsRemainingComponent from '../CommonComponents/SpotsRemainingComponent'
import SpotsLeftComponent from '../CommonComponents/SpotsLeftComponent'
import FooterComponent from '../CommonComponents/FooterComponent'
import routes from '../../../routes'
import AdvantagesComponent from '../CommonComponents/AdvantagesComponent'
import TabComponent from './TabComponent'
import CreditCardComponent from './CreditCardComponent'
import BankAccountComponent from './BankAccountComponent'

function PaymentForm() {
    const history = useHistory()
    const [firstName, setFirstName] = useState()

    useEffect(() => {
        setFirstName(localStorage.getItem('firstName'))
    }, [firstName])

    return (
        <>
            <div>
                <HeaderComponent />
            </div>
            <Row>
                <Col xs={24} xl={12} className={styles.frame}>
                    <>
                        <Row>
                            <Col xs={24} xl={24} className={'payment_header'}>
                                <div className={'almost_done_desktop'}>
                                    <h2>Almost done</h2>
                                </div>
                                <div className={'hi_name_mobile'}>Hi {firstName}!</div>
                                <div className={'you_are_on_your_way_mobile'}>
                                    <p>You are on your way to go green</p>
                                </div>
                                <div className={'we_just_need_desktop'}>
                                    <h3>We just need your payment details</h3>
                                </div>
                                <div className={'small_blue_highlight'} />
                            </Col>
                        </Row>
                        <div className={'spots_left_component'}>
                            <SpotsLeftComponent />
                        </div>
                        <Row>
                            <Col xs={24} xl={24} className={'you_wont_be_charged'}>
                                <p className={'your_payment_details'}>Your payment details</p>
                                <p>
                                    You won't be charged until your eligibility has been confirmed
                                    and you're fully connected.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{marginBottom: '1.4rem'}} xs={24} xl={24}>
                                <span
                                    onClick={() => history.push(routes.onBoardingUtility)}
                                    className={'backText'}
                                >
                                    {'< Back'}
                                </span>
                            </Col>
                        </Row>
                        <Row className={'tabContainer'}>
                            <Col span={24}>
                                <Row align="center">
                                    <TabComponent
                                        CreditCardComponent={CreditCardComponent}
                                        BankAccountComponent={BankAccountComponent}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </>
                </Col>
                <Col xs={24} xl={12}>
                    <div className={'spots_remaining_component'}>
                        {' '}
                        <SpotsRemainingComponent />{' '}
                    </div>
                </Col>
            </Row>
            <div className={'advantages_component_payment'}>
                <AdvantagesComponent />
            </div>
            <div className="reviews_component">
                <ReviewsComponent />
            </div>
            <div>
                <FooterComponent />
            </div>
        </>
    )
}

export default PaymentForm
