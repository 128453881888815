import {gql} from '@apollo/client'

export const GET_UTILITY_BY_ZIPCODE = gql`
    query utility($zipcode: String!) {
        utility(zipcode: $zipcode) {
            zipcode
            utilityName
            utilityId
        }
    }
`
