import {Input} from 'antd'
import classNames from 'classnames'
import styles from './LoginInput.module.css'

function LoginInput({className, ...props}) {
    const computedClassName = classNames(className, styles.loginInput)
    return <Input className={computedClassName} {...props} />
}

export default LoginInput
