import React, {useState, useCallback} from 'react'
import {Button, Form, Modal, Col, Input, Select} from 'antd'
import styles from './BankAccountComponent.module.css'
import {
    callPaymentKeyGet,
    callPaymentSourcePost,
    callSignUpPut,
    callStripeBankAccountPost,
    callSubscriberGet
} from '../../api/call-api-axios'
import {useHistory} from 'react-router-dom'
import routes from '../../../routes'
import {googleTagPaymentStepFailed, googleTagPaymentStepSuccess} from '../../util/googleTagManager'

const {Option} = Select

const fieldsValidation = {
    accountName: [
        {
            required: true,
            message: 'Account name is required'
        }
    ],
    accountHolderType: [
        {
            required: true,
            message: 'Account type is required'
        }
    ],
    routingNumber: [
        {
            required: true,
            message: 'Routing number is required'
        },
        {
            pattern: /^[0-9]{3,15}$/,
            message: 'Should be between 3 and 15 characters'
        }
    ],
    accountNumber: [
        {
            required: true,
            message: 'Account number is required'
        },
        {
            pattern: /^[0-9]{5,17}$/,
            message: 'Should be between 5 and 17 characters'
        }
    ]
}

const BankAccountComponent = () => {
    const history = useHistory()
    const [isButtonDisabled, setButtonDisabled] = useState(false)

    const resetStates = useCallback(() => {
        localStorage.clear()
        const queryParams = new URLSearchParams(window.location.search)
        const utmParameters = {
            utmCampaign: queryParams.get('utm_campaign'),
            utmSource: queryParams.get('utm_source'),
            utmMedium: queryParams.get('utm_medium'),
            timeClicked: new Date(),
            zipCode: queryParams.get('zip_code'),
            firstName: queryParams.get('first_name') || '',
            lastName: queryParams.get('last_name') || '',
            email: queryParams.get('email') || '',
            phoneNumber: queryParams.get('phone') || ''
        }
        localStorage.setItem('utmParameters', JSON.stringify(utmParameters))
    }, [])

    const onFinish = async (values) => {
        try {
            setButtonDisabled(true)

            const paymentKey = await callPaymentKeyGet()

            const responseStripeBankAccount = await callStripeBankAccountPost(
                {...values, country: 'US', currency: 'usd'},
                paymentKey['api-key']
            )

            const paymentSourceResponse = await callPaymentSourcePost(
                responseStripeBankAccount.id,
                'direct_debit'
            )

            if (
                paymentSourceResponse.status === 'valid' ||
                paymentSourceResponse.status === 'pending_verification'
            ) {
                const subscriberResponse = await callSubscriberGet()
                const subscriber = subscriberResponse.data

                if (subscriber.subscriberStatus !== 'BILL UPLOADED') {
                    await callSignUpPut({subscriberStatus: 'ONBOARDING COMPLETE'})
                }

                googleTagPaymentStepSuccess()

                history.push(routes.onBoardingConfirmation)
            } else {
                googleTagPaymentStepFailed()
                setButtonDisabled(false)
                showErrorModal()
            }
        } catch (error) {
            if (error.message === 'Token is not valid') {
                setButtonDisabled(false)
                console.error(error)
                resetStates()
                history.push(routes.onBoardingSignup)
            } else {
                googleTagPaymentStepFailed()
                setButtonDisabled(false)
                showErrorModal()
                console.error(error)
            }
        }
    }

    const showErrorModal = () => {
        Modal.error({
            className: styles.errorModal,
            title: <div style={{fontWeight: 'bold'}}>Check your bank account details</div>,
            content:
                'Problem while processing your payment method. Please verify your payment method details.'
        })
    }

    return (
        <div className={styles.bankAccountFormContainer}>
            <Form name="bank-account-form" onFinish={onFinish}>
                <Col span={24} className={styles.bankAccountInputContainer}>
                    <Form.Item
                        label="Name on the account"
                        name="account_holder_name"
                        className={styles.bankAccountFormItem}
                        rules={fieldsValidation.accountName}
                    >
                        <Input
                            style={{
                                minWidth: '279px',
                                border: 'hidden'
                            }}
                            placeholder="Enter your account name"
                        />
                    </Form.Item>
                </Col>
                <Col span={24} className={styles.bankAccountInputContainer}>
                    <Form.Item
                        label="Account holder type"
                        name="account_holder_type"
                        className={styles.bankAccountFormItem}
                        rules={fieldsValidation.accountHolderType}
                    >
                        <Select
                            placeholder="Enter your account type"
                            style={{
                                minWidth: '279px',
                                border: 'hidden'
                            }}
                            className={styles.bankAccountSelect}
                        >
                            <Option value="individual">Individual</Option>
                            <Option value="company">Company</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24} className={styles.bankAccountInputContainer}>
                    <Form.Item
                        label="Routing number"
                        name="routing_number"
                        className={styles.bankAccountFormItem}
                        rules={fieldsValidation.routingNumber}
                    >
                        <Input
                            type="number"
                            min={0}
                            maxLength={15}
                            style={{
                                minWidth: '279px',
                                border: 'hidden'
                            }}
                            placeholder="Enter your routing number"
                        />
                    </Form.Item>
                </Col>
                <Col span={24} className={styles.bankAccountInputContainer}>
                    <Form.Item
                        label="Account number"
                        name="account_number"
                        className={styles.bankAccountFormItem}
                        rules={fieldsValidation.accountNumber}
                    >
                        <Input
                            min={0}
                            maxLength={17}
                            type="number"
                            style={{
                                minWidth: '279px',
                                border: 'hidden'
                            }}
                            placeholder="Enter your account number"
                        />
                    </Form.Item>
                </Col>
                <Col span={24} className={styles.bankAccountInputContainer}>
                    <Form.Item className={styles.bankAccountAddButtonContainer}>
                        <Button
                            className={styles.bankAccountAddButton}
                            type="primary"
                            htmlType="submit"
                            disabled={isButtonDisabled}
                            name="Proceed to review"
                        >
                            Proceed to review
                        </Button>
                    </Form.Item>
                </Col>
            </Form>
        </div>
    )
}

export default BankAccountComponent
