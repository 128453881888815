import React from 'react'

const EmailIcon = () => {
    return (
        <div style={{marginRight: '3%'}}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="mail_outline_24px">
                    <path
                        id="icon/communication/mail_outline_24px"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM4 8L12 13L20 8V18H4V8ZM4 6L12 11L20 6H4Z"
                        fill="#005ebe"
                    />
                </g>
            </svg>
        </div>
    )
}

export default EmailIcon
