import {useEffect, useState} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import styles from './OnboardingPage.module.css'
import Page from '../Common/Page'
import routes from '../../routes'
import {Spin} from 'antd'
import AccountCreationForm from './AccountCreationForm/AccountCreationForm'
import UtilityLinkForm from './UtilityLinkForm/UtilityLinkForm'
import EnergyProfileForm from './EnergyProfileForm/EnergyProfileForm'
import PaymentForm from './PaymentForm/PaymentForm'
import ConfirmationForm from './ConfirmationForm/ConfirmationForm'
import WaitlistForm from './WaitlistForm/WaitlistForm'
import {callValidateZipGet} from '../api/call-api-axios'
import {useHistory} from 'react-router-dom'

function OnboardingPage(props) {
    const history = useHistory()
    const [convered, setCovered] = useState()
    const token = localStorage.getItem('substkn')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!token) {
            history.push({
                path: `${routes.onBoardingSignup}`,
                search: window.location.search
            })
        }
    }, [token, history])

    useEffect(() => {
        let cancel = false
        const queryParams = new URLSearchParams(window.location.search)

        if (
            localStorage.getItem('firstName') !== queryParams.get('first_name') ||
            localStorage.getItem('lastName') !== queryParams.get('last_name') ||
            localStorage.getItem('email') !== queryParams.get('email') ||
            localStorage.getItem('phone') !== queryParams.get('phone')
        ) {
            localStorage.clear()
        }

        const utmParameters = {
            utmCampaign: queryParams.get('utm_campaign'),
            utmSource: queryParams.get('utm_source'),
            utmMedium: queryParams.get('utm_medium'),
            timeClicked: new Date(),
            zipCode: queryParams.get('zip_code'),
            firstName: queryParams.get('first_name') || '',
            lastName: queryParams.get('last_name') || '',
            email: queryParams.get('email') || '',
            phoneNumber: queryParams.get('phone') || ''
        }

        localStorage.setItem('utmParameters', JSON.stringify(utmParameters))

        if (queryParams.get('zip_code')) {
            callValidateZipGet(queryParams.get('zip_code')).then((response) => {
                setLoading(false)
                if (response.message === 'Zipcode is supported') {
                    setCovered('Supported')
                } else {
                    setCovered('Not Supported')
                }
                if (cancel) return
            })
        } else {
            setCovered('Supported')
            setLoading(false)
        }
        return () => {
            cancel = true
        }
    }, [])

    if (convered === 'Supported' && !loading) {
        return (
            <Page>
                <div className={styles.frame}>
                    <Switch>
                        <Route
                            path={`${routes.onBoardingSignup}/:tempLinkId?`}
                            component={AccountCreationForm}
                        />
                        <Route
                            path={routes.onBoardingUtility}
                            render={(props) => <UtilityLinkForm {...props} />}
                        />
                        <Route
                            path={routes.onBoardingEnergyProfile}
                            component={EnergyProfileForm}
                        />
                        <Route path={routes.onBoardingPayment} component={PaymentForm} />
                        <Route path={routes.onBoardingConfirmation} component={ConfirmationForm} />
                        <Redirect from={routes.onBoarding} to={routes.onBoardingSignup}></Redirect>
                    </Switch>
                </div>
            </Page>
        )
    } else if (convered === 'Not Supported' && !loading) {
        return (
            <Page>
                <div className={styles.frame}>
                    <Switch>
                        <Route
                            path={routes.onBoardingWaitlist}
                            component={() => <WaitlistForm />}
                        />
                        <Redirect
                            from={routes.onBoarding}
                            to={routes.onBoardingWaitlist}
                        ></Redirect>
                    </Switch>
                </div>
            </Page>
        )
    } else {
        return (
            <Page>
                <div className="spinload" align="center">
                    <br />
                    <Spin tip="Validating Zipcode..." />
                </div>
            </Page>
        )
    }
}

export default OnboardingPage
