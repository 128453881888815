import TagManager from 'react-gtm-module'

// Account creation form validations
export function googleTagOnboardingSuccess(body, zipCode, subscriberId) {
    TagManager.dataLayer({
        dataLayer: {
            event: 'onboardingForm',
            status: 'success',
            stepNumber: '001',
            stepName: 'OnboardingStep',
            user_data: {
                subscriber_id: subscriberId || '',
                phone_number: body.phoneNumber,
                email_address: body.email,
                first_name: body.firstName,
                last_name: body.lastName,
                address: {
                    postal_code: zipCode || ''
                }
            }
        }
    })
}

export function googleTagOnboardingFailed(body, zipCode) {
    TagManager.dataLayer({
        dataLayer: {
            event: 'onboardingForm',
            status: 'failed',
            stepNumber: '001',
            stepName: 'OnboardingStep',
            user_data: {
                phone_number: body.phoneNumber || '',
                email_address: body.email || '',
                first_name: body.firstName || '',
                last_name: body.lastName || '',
                address: {
                    postal_code: zipCode || ''
                }
            }
        }
    })
}

// Wait list validations
export function googleTagWaitListSuccess(body, zipCode, subscriberId) {
    TagManager.dataLayer({
        dataLayer: {
            event: 'waitlistForm',
            status: 'success',
            user_data: {
                subscriber_id: subscriberId || '',
                phone_number: body.phoneNumber,
                email_address: body.email,
                first_name: body.firstName,
                last_name: body.lastName,
                address: {
                    postal_code: zipCode || ''
                }
            }
        }
    })
}

export function googleTagWaitListFailed(body, zipCode) {
    TagManager.dataLayer({
        dataLayer: {
            event: 'waitlistForm',
            status: 'failed',
            user_data: {
                phone_number: body.phoneNumber || '',
                email_address: body.email || '',
                first_name: body.firstName || '',
                last_name: body.lastName || '',
                address: {
                    postal_code: zipCode || ''
                }
            }
        }
    })
}

// Payment form validations
export function googleTagPaymentStepSuccess() {
    TagManager.dataLayer({
        dataLayer: {
            event: 'onboardingForm',
            status: 'success',
            stepNumber: '003',
            stepName: 'PaymentStep'
        }
    })
}

export function googleTagPaymentStepFailed() {
    TagManager.dataLayer({
        dataLayer: {
            event: 'onboardingForm',
            status: 'failed',
            stepNumber: '003',
            stepName: 'PaymentStep'
        }
    })
}

// Utility link form validations
export function googleTagUtilitylinkFormSuccess_Step_A() {
    TagManager.dataLayer({
        dataLayer: {
            event: 'onboardingForm',
            status: 'success',
            stepNumber: '002 - A',
            stepName: 'UtilityStepA'
        }
    })
}

export function googleTagUtilitylinkFormFailed_Step_A() {
    TagManager.dataLayer({
        dataLayer: {
            event: 'onboardingForm',
            status: 'failed',
            stepNumber: '002 - A',
            stepName: 'UtilityStepA'
        }
    })
}

export function googleTagPepcoLoginSuccess_Step_B() {
    TagManager.dataLayer({
        dataLayer: {
            event: 'onboardingForm',
            status: 'success',
            stepNumber: '002 - B',
            stepName: 'UtilityStepBPepco'
        }
    })
}

export function googleTagPepcoLoginFailed_Step_B() {
    TagManager.dataLayer({
        dataLayer: {
            event: 'onboardingForm',
            status: 'failed',
            stepNumber: '002 - B',
            stepName: 'UtilityStepBPepcp'
        }
    })
}

export function googleTagPepcoDocsSuccess_Step_B() {
    TagManager.dataLayer({
        dataLayer: {
            event: 'onboardingForm',
            status: 'success',
            stepNumber: '002 - B',
            stepName: 'UtilityStepBDocs'
        }
    })
}

export function googleTagPepcoDocsFailed_Step_B() {
    TagManager.dataLayer({
        dataLayer: {
            event: 'onboardingForm',
            status: 'failed',
            stepNumber: '002 - B',
            stepName: 'UtilityStepBDocs'
        }
    })
}
