import React from 'react'
import {Row, Col} from 'antd'
import styles from './Common.module.css'
import {googleReviews, imgDanielleJackson, imgDerwinStraight} from '../../../assets'
import {stars} from '../../../assets'
import SuccessStoriesComponent from './SuccessStoriesComponent'
import {Carousel} from 'antd'
import './Common.module.css'

const ReviewsComponent = () => (
    <>
        <Row>
            <Col xs={24} xl={24}>
                <div align="center">
                    <div style={{marginTop: '50px'}}>
                        <h1 className={styles.bottom_sentence_first_line}>
                            Who you’ll be sharing the sun with
                        </h1>
                    </div>
                    <div>
                        <h2 className={styles.bottom_sentence_second_line}>
                            Others already{' '}
                            <span className={styles.bottom_sentence_second_line_2}>
                                saving with community solar
                            </span>
                        </h2>
                    </div>
                    <div className={styles.blue_highlight_bottom} />
                </div>
            </Col>
        </Row>
        <div className={styles.success_stories_box}>
            <SuccessStoriesComponent
                userPhoto={imgDanielleJackson}
                star={stars}
                userName="Danielle Jackson"
                farmLocation="Fort Chaplin Solar Farm"
                userStory="The money I’ve been saving thanks to the solar panels – it’s been wonderful, especially in the summer months, and with the quarantine, when my bills really spiked."
            />
            <SuccessStoriesComponent
                userPhoto={imgDerwinStraight}
                star={stars}
                userName="Derwin Straight "
                farmLocation="Fort Chaplin Solar Farm"
                userStory="SaveSolar is coming through with some real help and people need help right now with this crisis going on. Can you imagine last year, me getting the same bill with this coronavirus? Oh boy, you guys are right on time."
            />
        </div>
        <Carousel className={styles.carousel} autoplay autoplaySpeed={5000}>
            <div className={styles.success_story_carousel_item}>
                <SuccessStoriesComponent
                    userPhoto={imgDanielleJackson}
                    star={stars}
                    userName="Danielle Jackson"
                    farmLocation="Fort Chaplin Solar Farm"
                    userStory="The money I’ve been saving thanks to the solar panels – it’s been wonderful, especially in the summer months, and with the quarantine, when my bills really spiked."
                />
            </div>
            <div className={styles.success_story_carousel_item}>
                <SuccessStoriesComponent
                    userPhoto={imgDerwinStraight}
                    star={stars}
                    userName="Derwin Straight "
                    farmLocation="Fort Chaplin Solar Farm"
                    userStory="SaveSolar is coming through with some real help and people need help right now with this crisis going on. Can you imagine last year, me getting the same bill with this coronavirus? Oh boy, you guys are right on time."
                />
            </div>
        </Carousel>
        <Row className={styles.average_rate}>
            <Col xs={24} xl={24}>
                <h2 style={{color: 'gray', textAlign: 'center'}}>
                    Average
                    <span>
                        <strong> 5 star rating </strong>
                    </span>
                    on Google Reviews
                    <img
                        alt="Google Reviews Logo"
                        src={googleReviews}
                        style={{marginLeft: '25px'}}
                    />
                </h2>
            </Col>
        </Row>
    </>
)

export default ReviewsComponent
