import React, {useState, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {Button, Form, DatePicker, InputNumber, Col, Modal} from 'antd'
import styles from './CreditCardComponent.module.css'
import moment from 'moment'
import validator from 'validator'
import {
    callPaymentKeyGet,
    callPaymentSourcePost,
    callSignUpPut,
    callStripeCardPost,
    callSubscriberGet
} from '../../api/call-api-axios'
import routes from '../../../routes'
import {googleTagPaymentStepFailed, googleTagPaymentStepSuccess} from '../../util/googleTagManager'

const disabledDate = (current) => {
    return current && current < moment().endOf('day')
}

const formatCreditCardNumber = (value) => {
    if (!value) {
        return value
    }
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    const matches = v.match(/\d{4,20}/g)
    const match = (matches && matches[0]) || ''
    const parts = []
    for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4))
    }
    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
}

const fieldsValidation = {
    cardNumber: [
        {
            required: true,
            message: 'Card number is required'
        },
        {
            validator: async (_, value) => {
                if (!value || validator.isCreditCard(value.toString())) {
                    return Promise.resolve()
                } else {
                    throw new Error('Please enter a valid credit card number')
                }
            },
            message: 'Please enter a valid credit card number'
        }
    ],
    cvvNumber: [
        {
            required: true,
            message: 'CVV number is required'
        },
        {
            pattern: /^[0-9]{3,4}$/,
            message: 'Please enter a valid CVV number'
        }
    ]
}

const CreditCardComponent = () => {
    const [isButtonDisabled, setButtonDisabled] = useState(false)
    const history = useHistory()

    const resetStates = useCallback(() => {
        localStorage.clear()
        const queryParams = new URLSearchParams(window.location.search)
        const utmParameters = {
            utmCampaign: queryParams.get('utm_campaign'),
            utmSource: queryParams.get('utm_source'),
            utmMedium: queryParams.get('utm_medium'),
            timeClicked: new Date(),
            zipCode: queryParams.get('zip_code'),
            firstName: queryParams.get('first_name') || '',
            lastName: queryParams.get('last_name') || '',
            email: queryParams.get('email') || '',
            phoneNumber: queryParams.get('phone') || ''
        }
        localStorage.setItem('utmParameters', JSON.stringify(utmParameters))
    }, [])

    const showErrorModal = () => {
        Modal.error({
            className: styles.errorModal,
            title: <div style={{fontWeight: 'bold'}}>Check your credit card details</div>,
            content:
                'Problem while processing your payment method. Please verify your payment method details.'
        })
    }

    const onFinish = async (values) => {
        try {
            setButtonDisabled(true)

            const paymentKey = await callPaymentKeyGet()

            const creditCardData = {
                number: values.card_number,
                exp_month: values.month_picker.month() + 1,
                exp_year: values.month_picker.year(),
                cvc: values.cvv
            }

            const responseStripeCard = await callStripeCardPost(
                creditCardData,
                paymentKey['api-key']
            )

            const paymentSourceResponse = await callPaymentSourcePost(responseStripeCard.id, 'card')

            if (
                paymentSourceResponse.status === 'valid' ||
                paymentSourceResponse.status === 'pending_verification'
            ) {
                const subscriberResponse = await callSubscriberGet()
                const subscriber = subscriberResponse.data

                if (subscriber.subscriberStatus !== 'BILL UPLOADED') {
                    await callSignUpPut({subscriberStatus: 'ONBOARDING COMPLETE'})
                }

                googleTagPaymentStepSuccess()

                history.push(routes.onBoardingConfirmation)
            } else {
                googleTagPaymentStepFailed()
                setButtonDisabled(false)
                showErrorModal()
            }
        } catch (error) {
            if (error.message === 'Token is not valid') {
                setButtonDisabled(false)
                console.error(error)
                resetStates()
                history.push(routes.onBoardingSignup)
            } else {
                googleTagPaymentStepFailed()
                setButtonDisabled(false)
                showErrorModal()
                console.error(error)
            }
        }
    }

    return (
        <div className={styles.creditCardFormContainer}>
            <Form name="credit-card-form" onFinish={onFinish}>
                <Col span={24} className={styles.cardNumberContainer}>
                    <Form.Item
                        label="Card number"
                        name="card_number"
                        className={styles.cardNumber}
                        rules={fieldsValidation.cardNumber}
                    >
                        <InputNumber
                            min={0}
                            maxLength={19}
                            formatter={formatCreditCardNumber}
                            style={{
                                minWidth: '279px',
                                border: 'hidden'
                            }}
                            placeholder="Enter your card number"
                        />
                    </Form.Item>
                </Col>
                <div className={styles.expiryCvvDiv}>
                    <Form.Item style={{margin: '0px'}}>
                        <div className={styles.expiryCvvContainer}>
                            <div className={styles.expiryContainer}>
                                <Form.Item
                                    name="month_picker"
                                    label="Expiry"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Expiration date is required'
                                        }
                                    ]}
                                    className={styles.expiry}
                                >
                                    <DatePicker
                                        disabledDate={disabledDate}
                                        style={{
                                            padding: '0px',
                                            border: 'none',
                                            width: '100%'
                                        }}
                                        suffixIcon={null}
                                        picker="month"
                                        placeholder="MM/YYYY"
                                        format={'MM/YYYY'}
                                    />
                                </Form.Item>
                            </div>
                            <div className={styles.cvvContainer}>
                                <Form.Item
                                    label="CVV"
                                    name="cvv"
                                    rules={fieldsValidation.cvvNumber}
                                    className={styles.cvv}
                                >
                                    <InputNumber
                                        style={{width: '100%', border: 'hidden'}}
                                        min={0}
                                        minLength={3}
                                        maxLength={4}
                                        placeholder="XXX"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </Form.Item>
                </div>
                <Form.Item className={styles.creditCardAddButtonContainer}>
                    <Button
                        disabled={isButtonDisabled}
                        className={styles.creditCardAddButton}
                        type="primary"
                        htmlType="submit"
                    >
                        Add
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default CreditCardComponent
