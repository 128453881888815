import React, {useState} from 'react'
import {Modal, Button} from 'antd'
import BillModalStep1 from './BillModalStep1/BillModalStep1'
import BillModalStep2 from './BillModalStep2/BillModalStep2'
import ConfirmationPage from './BillModalConfirmation/Confirmation'
import {
    googleTagPepcoDocsSuccess_Step_B,
    googleTagPepcoDocsFailed_Step_B
} from '../../util/googleTagManager'

import styles from './UploadBillModal.module.css'

const UploadBillModal = () => {
    const [isVisible, setIsVisible] = useState(false)
    const [isUploadCliked, setIsUploadClicked] = useState(false)
    const [isConfirmed, setIsConfirmed] = useState(false)

    const showModal = () => {
        googleTagPepcoDocsSuccess_Step_B()
        setIsVisible(true)
    }
    const hideModal = () => {
        googleTagPepcoDocsFailed_Step_B()
        setIsVisible(false)
    }

    const showStep2 = () => {
        setIsUploadClicked(true)
    }

    const showConfirmation = () => {
        setIsConfirmed(true)
    }

    return (
        <>
            <Button
                type="primary"
                data-testid="uploadBill-toggle-button"
                block
                style={{
                    marginTop: '10px',
                    backgroundColor: 'transparent',
                    border: '2px solid #005ebe',
                    borderRadius: '4px',
                    fontWeight: '600',
                    color: '#005ebe',
                    height: '53px',
                    whiteSpace: 'initial'
                }}
                onClick={showModal}
            >
                Don't have Pepco account details?
            </Button>
            <Modal
                data-testid="uploadBill-toggle-modal"
                className={styles.UploadBillModal}
                visible={isVisible}
                onCancel={hideModal}
                footer={null}
                centered
            >
                {!isConfirmed ? (
                    <>
                        <h3 className={styles.title}>
                            {!isUploadCliked
                                ? "Don't have your Pepco account details handy?"
                                : 'Upload your bill'}
                        </h3>
                        {!isUploadCliked ? (
                            <BillModalStep1 onUpload={showStep2} />
                        ) : (
                            <BillModalStep2 onConfirm={showConfirmation} />
                        )}
                    </>
                ) : (
                    <>
                        <ConfirmationPage />
                    </>
                )}
            </Modal>
        </>
    )
}

export default UploadBillModal
