import {useState, Fragment} from 'react'
import {Form, notification, Typography} from 'antd'
import Spinner from '../../Common/Spinner/Spinner'
import LoginButton from '../LoginButton/LoginButton'
import LoginInput from '../LoginInput/LoginInput'

import styles from './SendCodeModal.module.css'
import {resetPassword} from '../../auth/auth'
import {currentUserVar} from '../../../graphQL/cache'
import {useHistory} from 'react-router-dom'
import routes from '../../../routes'

const {Text} = Typography

function SendCodeSecondStep({forgotPasswordAddress, onCancel}) {
    const [resetPasswordForm] = Form.useForm()
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)
    const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true)
    const [passwordsMatch, setPasswordMatch] = useState(true)

    function onSubmit(values) {
        setIsLoading(true)
        resetPassword(
            forgotPasswordAddress,
            values.code,
            values.newPassword,
            onResetPasswordResponse
        )
    }

    function onResetPasswordResponse(response, currentUser, error) {
        setIsLoading(false)
        if (response === 'success') {
            currentUserVar(currentUser)
            history.push(routes.dashboard)
        } else if (response === 'failure') {
            resetPasswordForm.resetFields()
            notification.error({message: 'Your password was not reset', description: error})
        }
    }

    function onValuesChange(changedValues, allValues) {
        const passwordsMatch = allValues.newPassword === allValues.confirmNewPassword
        const enableResetButton =
            allValues.newPassword &&
            allValues.confirmNewPassword &&
            allValues.code &&
            passwordsMatch
        setPasswordMatch(passwordsMatch)
        setIsResetButtonDisabled(!enableResetButton)
    }

    return (
        <Fragment>
            <Form form={resetPasswordForm} onFinish={onSubmit} onValuesChange={onValuesChange}>
                <p>Please input the code we sent to your email along with a new password here:</p>
                {isLoading && <Spinner className={styles.spinner} />}
                <Form.Item name="code">
                    <LoginInput name="code" autoComplete="new-password" placeholder="Reset Code" />
                </Form.Item>
                <Form.Item name="newPassword">
                    <LoginInput
                        name="newPassword"
                        type="password"
                        autoComplete="new-password"
                        placeholder="New Password"
                    />
                </Form.Item>
                <Form.Item name="confirmNewPassword">
                    <LoginInput
                        name="confirmNewPassword"
                        type="password"
                        autoComplete="new-password"
                        placeholder="Confirm New Password"
                    />
                </Form.Item>
                <div>{!passwordsMatch && <Text type="danger">Passwords do not match</Text>}</div>
                <LoginButton
                    htmlType="submit"
                    value="reset password"
                    type="primary"
                    className={styles.sendCodeAuxBtn}
                    disabled={isResetButtonDisabled}
                    block
                >
                    Reset Password
                </LoginButton>
                <LoginButton
                    className={styles.sendCodeAuxBtn}
                    value="cancel"
                    htmlType="button"
                    type="primary"
                    onClick={onCancel}
                    block
                >
                    Cancel
                </LoginButton>
            </Form>
        </Fragment>
    )
}

export default SendCodeSecondStep
