import {React} from 'react'
import {useQuery} from '@apollo/client'
import {Row, Col} from 'antd'
import './SpotsLeft.responsive.css'
import {GET_FACILITY_SHOW_LOCAL_FARM} from '../../../graphQL/queries/facility'
import {facilityUrlEndpoint} from '../../config/index'

function SpotsLeftComponent() {
    const {data} = useQuery(GET_FACILITY_SHOW_LOCAL_FARM, {
        fetchPolicy: 'network-only'
    })

    const localFarm = data?.facilityShowLocalFarm[0] || {}

    return (
        <>
            <Row>
                <Col xs={24} xl={24}>
                    <div className={'spots_card_container'}>
                        <div className={'spots_card_title'}>
                            <h3 className={'spots_card_title_text'}>
                                Your closest solar farm at
                                {localFarm.siteName ? ` (${localFarm.siteName})` : ''}
                            </h3>
                        </div>
                        <div className={'spots_card_subcontainer'}>
                            {localFarm.facilityImageUrl ? (
                                <img
                                    className="spots_background_image"
                                    alt="Facility"
                                    src={`${facilityUrlEndpoint}/${localFarm.facilityImageUrl}`}
                                />
                            ) : (
                                ''
                            )}
                            <h2 className={'spots_number_text'}>18</h2>
                            <h2 className={'spots_left_text'}>Spots left</h2>
                            <h2 className={'dont_miss_text'}>
                                Don’t miss the opportunity to save{' '}
                                <span style={{fontWeight: '600'}}>$120/year</span>
                            </h2>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default SpotsLeftComponent
