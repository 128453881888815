import {apiEndpoint} from './sync-api-utility-endpoint/api-utility-endpoint'
import {tokenIsSane} from '../auth/token-sanity-check'

export function callSignUpPost(bodyReq, callback, errorcallback) {
    const res = fetch(apiEndpoint + '/sign-up', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: bodyReq.email,
            firstName: bodyReq.firstName,
            lastName: bodyReq.lastName,
            phoneNumber: bodyReq.phoneNumber,
            trackingId: bodyReq.trackingId,
            subscriberStatus: bodyReq.subscriberStatus
        })
    })
        .then((response) => {
            localStorage.setItem('substkn', response.headers.get('token'))
            return response.json()
        })
        .then((responseJson) => {
            if (callback != null) {
                callback(responseJson)
            }
        })
        .catch((error) => {
            errorcallback(error)
        })
    return res
}

export function callSubscriberGet(callback, errorcallback) {
    let token = localStorage.getItem('substkn')
    if (tokenIsSane(token) === true) {
        const res = fetch(apiEndpoint + '/subscriber', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                return new Promise((resolve) => {
                    resolve(response.json())
                }).then((responseJSON) => {
                    return {...responseJSON, status: response.status}
                })
            })
            .then((responseJson) => {
                if (callback != null) {
                    callback(responseJson)
                }
            })
            .catch((error) => {
                errorcallback(error)
            })
        return res
    } else {
        throw new Error('Token is not valid')
    }
}

export function callUtilityAccountPost(token, bodyReq, callback, errorcallback) {
    if (tokenIsSane(token) === true) {
        const res = fetch(apiEndpoint + '/utility-account', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify({
                username: bodyReq.username,
                password: bodyReq.password
            })
        })
            .then((response) => {
                return response.json()
            })
            .then((responseJson) => {
                if (callback != null) {
                    callback(responseJson)
                }
            })
            .catch((error) => {
                errorcallback(error)
            })
        return res
    } else {
        throw new Error('Token is not valid')
    }
}

export function callUtilityAccountPut(token, bodyReq, callback, errorcallback) {
    if (tokenIsSane(token) === true) {
        const res = fetch(apiEndpoint + '/utility-account', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify({
                utilityAccountId: bodyReq.utilityAccountId,
                credentials: {
                    username: bodyReq.username,
                    password: bodyReq.password
                }
            })
        })
            .then((response) => {
                return response.json()
            })
            .then((responseJson) => {
                if (callback != null) {
                    callback(responseJson)
                }
            })
            .catch((error) => {
                errorcallback(error)
            })
        return res
    } else {
        throw new Error('Token is not valid')
    }
}

export function callMfaCodePut(token, bodyReq, callback, errorcallback) {
    if (tokenIsSane(token) === true) {
        const res = fetch(apiEndpoint + '/mfa-code', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify({
                utilityAccountId: bodyReq.utilityAccountId,
                mfaCode: bodyReq.mfaCode
            })
        })
            .then((response) => {
                return response.json()
            })
            .then((responseJson) => {
                if (callback != null) {
                    callback(responseJson)
                }
            })
            .catch((error) => {
                errorcallback(error)
            })
        return res
    } else {
        throw new Error('Token is not valid')
    }
}
