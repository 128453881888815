import React from 'react'
import {Row, Col} from 'antd'
import './Common.responsive.css'
import {logoBlueImage} from '../../../assets'

const HeaderComponent = () => (
    <>
        <Row>
            <Col xs={13} xl={8} className={'frame'}>
                <a href="https://www.savesolar.us/">
                    <img alt="SaveSolar Logo" src={logoBlueImage} className={'logo'} />
                </a>
            </Col>

            <Col xs={11} xl={16} className={'frame'}>
                <div className={'vertical_divider'}>
                    <h1 className={'logo_header'}>
                        The <span className={'logo_header_green'}>greener</span> way to save
                        electricity
                    </h1>
                </div>
            </Col>
        </Row>
        <hr className={'hr_header'}></hr>
    </>
)

export default HeaderComponent
