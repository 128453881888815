import React from 'react'
import {Col, Tabs} from 'antd'
import styles from './TabComponent.module.css'

const {TabPane} = Tabs

function TabComponent({BankAccountComponent, CreditCardComponent}) {
    return (
        <Col>
            <Tabs className={styles.creditTab} defaultActiveKey="Credit card">
                <TabPane tab="Credit card" key="Credit card">
                    <CreditCardComponent />
                </TabPane>
                {/* <TabPane tab="ACH" key="ACH">  This code will be uncommented when ACH account verification process is implemented.
                    <BankAccountComponent />
                </TabPane> */}
            </Tabs>
        </Col>
    )
}

export default TabComponent
