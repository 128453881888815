import React from 'react'

const TermsOfService = () => {
    return (
        <div>
            <h1
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '40px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                SaveSolar CSM Terms of Use
            </h1>
            <h1
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '40px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                Objective
            </h1>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                The purpose of this document is to store the currently approved ‘Terms of Use’ for
                the SaveSolar CSM Application
            </p>
            <h1
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '40px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                Terms of Use
            </h1>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Welcome to SaveSolar CSM !
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                These terms and conditions, together with any documents they expressly incorporate
                by reference, outline the rules and regulations for the use of SaveSolar Corporation
                Inc’s Website, located at www.savesolar.us.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Please read the Terms of Use carefully before you start to use the Website. &nbsp;By
                accessing this Website we assume you accept these terms and conditions. Do not
                continue to use SaveSolar CSM Application if you do not agree to take all of the
                terms and conditions stated on this page. Our Terms and Conditions were created with
                the help of the Terms And Conditions Generator and the Free Terms &amp; Conditions
                Generator. &nbsp;If you do not want to agree to these Terms of Use or the Privacy
                Policy, you must not access or use the Website.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                This Website is offered and available to users who are 18 years of age or older and
                reside in the United States or any of its territories or possessions. &nbsp;By using
                this Website, you represent and warrant that you are of legal age to form a binding
                contract with the Company and meet all of the foregoing eligibility requirements.
                &nbsp;If you do not meet all of these requirements, you must not access or use the
                Website.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                The following terminology applies to these Terms and Conditions, Privacy Statement
                and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you,
                the person log on this Website and compliant to the Company’s terms and conditions.
                “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”,
                “Parties”, or “Us”, refers to both the Client and ourselves. All terms refer to the
                offer, acceptance and consideration of payment necessary to undertake the process of
                our assistance to the Client in the most appropriate manner for the express purpose
                of meeting the Client’s needs in respect of provision Any use of the above
                terminology or other words in the singular, plural, capitalization and/or he/she or
                they, are taken as interchangeable and therefore as referring to same.
            </p>
            <h3
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    margin: '0px 0px 20px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '36px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Cookies
                </strong>
            </h3>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We employ the use of cookies. By accessing SaveSolar CSM Application, you agreed to
                use cookies in agreement with the SaveSolar Corporation Inc’s
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Most interactive Websites use cookies to let us retrieve the user’s details for each
                visit. Cookies are used by our Website to enable the functionality of certain areas
                to make it easier for people visiting our Website. Some of our affiliate/advertising
                partners may also use cookies.
            </p>
            <h3
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    margin: '0px 0px 20px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '36px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    License
                </strong>
            </h3>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Unless otherwise stated, SaveSolar Corporation Inc and/or its licensors own the
                intellectual property rights for all material on SaveSolar CSM Application. All
                intellectual property rights are reserved. You may access this from SaveSolar CSM
                Application for your own personal use subjected to restrictions set forth in these
                terms and conditions.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                You may not:
            </p>
            <ul
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Republish material from SaveSolar CSM Application
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Sell, rent or sub-license material from SaveSolar CSM Application
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Reproduce, duplicate or copy material from SaveSolar CSM Application
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Redistribute content from SaveSolar CSM Application
                </li>
            </ul>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                This Agreement shall begin on the date hereof.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Parts of this Website offer an opportunity for users to post and exchange opinions
                and information in certain areas of the Website. SaveSolar Corporation Inc does not
                filter, edit, publish or review your written comments to its Website (“Comments”)
                comments &nbsp;prior to their presence on the Website. Comments do not reflect the
                views and opinions of SaveSolar Corporation Inc, its agents and/or affiliates.
                Comments reflect the views and opinions of the person who post their views and
                opinions. To the extent permitted by applicable laws, SaveSolar Corporation Inc
                shall not be liable for the Comments or for any liability, damages or expenses
                caused and/or suffered as a result of any use of and/or posting of and/or appearance
                of the Comments on this Website.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                SaveSolar Corporation Inc reserves the right to monitor all Comments and to remove
                any Comments which can be considered inappropriate, offensive or causes breach of
                these Terms and Conditions.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                You warrant and represent that:
            </p>
            <ul
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    You are entitled to post the Comments on our Website and have all necessary
                    licenses and consents to do so;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    The Comments do not invade any intellectual property right, including without
                    limitation copyright, patent or trademark of any third party;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    The Comments do not contain any defamatory, libelous, offensive, indecent or
                    otherwise unlawful material which is an invasion of privacy
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    The Comments will not be used to solicit or promote business or custom or
                    present commercial activities or unlawful activity.
                </li>
            </ul>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                You hereby grant SaveSolar Corporation Inc a non-exclusive license to use,
                reproduce, edit and authorize others to use, reproduce and edit any of your Comments
                in any and all forms, formats or media.
            </p>
            <h3
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    margin: '0px 0px 20px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '36px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Hyperlinking to our Content
                </strong>
            </h3>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                The following organizations may link to our Website without your prior written
                approval:
            </p>
            <ul
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Government agencies;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Search engines;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    News organizations;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Online directory distributors may link to our Website in the same manner as they
                    hyperlink to the Websites of other listed businesses; and
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    System wide Accredited Businesses except soliciting non-profit organizations,
                    charity shopping malls, and charity fundraising groups which may not hyperlink
                    to our Web site.
                </li>
            </ul>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                These organizations may link to our home page, to publications or to other Website
                information so long as the link: (a) is not in any way deceptive; (b) does not
                falsely imply sponsorship, endorsement or approval of the linking party and its
                products and/or services; and (c) fits within the context of the linking party’s
                site.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We may consider and approve other link requests from the following types of
                organizations:
            </p>
            <ul
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    commonly-known consumer and/or business information sources;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    com community sites;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    associations or other groups representing charities;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    online directory distributors;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    internet portals;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    accounting, law and consulting firms; and
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    educational institutions and trade associations.
                </li>
            </ul>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We will approve link requests from these organizations if we decide that: (a) the
                link would not make us look unfavorably to ourselves or to our accredited
                businesses; (b) the organization does not have any negative records with us; (c) the
                benefit to us from the visibility of the hyperlink compensates the absence of
                SaveSolar Corporation Inc; and (d) the link is in the context of general resource
                information.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                These organizations may link to our home page so long as the link: (a) is not in any
                way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of
                the linking party and its products or services; and (c) fits within the context of
                the linking party’s site.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                If you are one of the organizations listed above and are interested in linking to
                our Website, you must inform us by sending an e-mail to Please include your name,
                your organization name, contact information as well as the URL of your site, a list
                of any URLs from which you intend to link to our Website, and a list of the URLs on
                our site to which you would like to link. Wait 2-3 weeks for a response.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Approved organizations may hyperlink to our Website as follows:
            </p>
            <ul
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    By use of our corporate name; or
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    By use of the uniform resource locator being linked to; or
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    By use of any other description of our Website being linked to that makes sense
                    within the context and format of content on the linking party’s site.
                </li>
            </ul>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                No use of SaveSolar Corporation Inc’s logo or other artwork will be allowed for
                linking absent a trademark license agreement.
            </p>
            <h3
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    margin: '0px 0px 20px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '36px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    iFrames
                </strong>
            </h3>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Without prior approval and written permission, you may not create frames around our
                Webpages that alter in any way the visual presentation or appearance of our Website.
            </p>
            <h3
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    margin: '0px 0px 20px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '36px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Content Liability
                </strong>
            </h3>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We shall not be held responsible for any content that appears on your Website. You
                agree to protect and defend us against all claims that may arise on your Website. No
                link(s) should appear on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates the infringement or
                other violation of, any third party rights.
            </p>
            <h3
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    margin: '0px 0px 20px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '36px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Your Privacy
                </strong>
            </h3>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Please read Privacy . &nbsp;You agree that all information you provide to register
                with this Website or otherwise, including but not limited to, through the use of any
                interactive features on the Website, is governed by our Privacy Policy, and you
                consent to all actions we take with respect to your information consistent with our
                Privacy Policy.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                If you choose, or are provided with, a user name, password, or any other piece of
                information as part of our security procedures, you must treat such information as
                confidential, and you must not disclose it to any other person or entity. You also
                acknowledge that your account is personal to you and agree not to provide any other
                person with access to this Website or portions of it using your user name, password,
                or other security information. You agree to notify us immediately of any
                unauthorized access to or use of your user name or password or any other breach of
                security. You also agree to ensure that you exit from your account at the end of
                each session. You should use particular caution when accessing your account from a
                public or shared computer so that others are not able to view or record your
                password or other personal information.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We have the right to disable any user name, password, or other identifier, whether
                chosen by you or provided by us, at any time if, in our opinion, you have violated
                any provision of these Terms of Use.
            </p>
            <h3
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    margin: '0px 0px 20px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '36px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Reservation of Rights
                </strong>
            </h3>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We reserve the right to request that you remove all links or any particular link to
                our Website. You agree to immediately remove all links to our Website upon request.
                We have the right to:
            </p>
            <ul
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Take any action with respect to any Comment that we deem necessary or
                    appropriate in our sole discretion, including if we believe that such Comment
                    violates the Terms of Use, infringes any intellectual property right or other
                    right of any person or entity, threatens the personal safety of users of the
                    Website or the public, or could create liability for the Company.
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Disclose your identity or other information about you to any third party who
                    claims that material posted by you violates their rights, including their
                    intellectual property rights or their right to privacy.
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Take appropriate legal action, including without limitation, referral to law
                    enforcement, for any illegal or unauthorized use of the Website.
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Terminate or suspend your access to all or part of the Website for any violation
                    of these Terms of Use.
                </li>
            </ul>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                Without limiting the foregoing, we have the right to cooperate fully with any law
                enforcement authorities or court order requesting or directing us to disclose the
                identity or other information of anyone posting any materials on or through the
                Website. &nbsp;YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES,
                LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY
                ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY
                SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We cannot review all material before it is posted on the Website and cannot ensure
                prompt removal of objectionable material after it is posted. &nbsp;Accordingly, we
                assume no liability for any action or inaction regarding transmissions,
                communications, or content provided by any user or third party.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We also reserve the right to amend these terms and conditions and the linking policy
                at any time. All changes are effective immediately when we post them and apply to
                all access to and use of the Website thereafter. &nbsp;By continuously linking to
                our Website, you agree to be bound to and follow these linking terms and conditions.
                &nbsp;You are expected to check this page each time you access this Website so you
                are aware of any changes, as they are binding on you.
            </p>
            <h3
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    margin: '0px 0px 20px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '36px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Removal of links from our Website
                </strong>
            </h3>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                If you find any link on our Website that is offensive for any reason, you are free
                to contact and inform us. We will consider requests to remove a link but we are not
                obligated to do so or to respond to you directly.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                We do not ensure that the information on this Website is correct, we do not warrant
                its completeness or accuracy; nor do we promise to ensure that the Website remains
                available or that the material on the Website is kept up to date.
            </p>
            <h3
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    margin: '0px 0px 20px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    padding: '0px',
                    fontFamily: 'Raleway, sans-serif',
                    fontSize: '36px',
                    color: 'rgb(0, 0, 0)'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Disclaimer
                </strong>
            </h3>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                To the maximum extent permitted by applicable law, we exclude all representations,
                warranties and conditions relating to our Website and the use of this Website.
                Nothing in this disclaimer will:
            </p>
            <ul
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px 35px',
                    padding: '0px',
                    listStylePosition: 'initial',
                    listStyleImage: 'initial'
                }}
            >
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    limit or exclude our or your liability for death or personal injury;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    limit or exclude our or your liability for fraud or fraudulent
                    misrepresentation;
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    limit any of our or your liabilities in any way that is not permitted under
                    applicable law; or
                </li>
                <li
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    exclude any of our or your liabilities that may not be excluded under applicable
                    law.
                </li>
            </ul>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                The limitations and prohibitions of liability set in this Section and elsewhere in
                this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all
                liabilities arising under the disclaimer, including liabilities arising in contract,
                in tort and for breach of statutory duty.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                As long as the Website and the information and services on the Website are provided
                free of charge, we will not be liable for any loss or damage of any nature.
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                <strong
                    style={{
                        WebkitFontSmoothing: 'antialiased',
                        boxSizing: 'inherit',
                        border: '0px rgb(226, 226, 226)',
                        outline: '0px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        margin: '0px',
                        padding: '0px'
                    }}
                >
                    Limitation on Time to File Claims
                </strong>
            </p>
            <p
                style={{
                    WebkitFontSmoothing: 'antialiased',
                    color: 'rgb(116, 116, 116)',
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontWeight: 400,
                    letterSpacing: 'normal',
                    orphans: 2,
                    textAlign: 'start',
                    textIndent: '0px',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    widows: 2,
                    wordSpacing: '0px',
                    WebkitTextStrokeWidth: '0px',
                    background: 'transparent',
                    textDecorationThickness: 'initial',
                    textDecorationStyle: 'initial',
                    textDecorationColor: 'initial',
                    boxSizing: 'inherit',
                    border: '0px rgb(226, 226, 226)',
                    outline: '0px',
                    verticalAlign: 'baseline',
                    margin: '0px 0px 20px',
                    padding: '0px'
                }}
            >
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THISE TERMS
                OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF
                ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIMS IS PERMANENTLY BARRED.
            </p>
        </div>
    )
}

export default TermsOfService
