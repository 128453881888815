import {Fragment} from 'react'
import styles from './EmailSettings.module.css'

function EmailSettings({userDetails = {}}) {
    return (
        <Fragment>
            <label className={styles.detailsTitle}>Your email address is currently set to:</label>
            <span className={styles.details}>{userDetails.email}</span>
        </Fragment>
    )
}

export default EmailSettings
