import {Layout, Typography} from 'antd'

import {useState} from 'react'
import styles from './DashboardPage.module.css'
import Sidebar from './Sidebar/Sidebar'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import SettingsPage from '../SettingsPage/SettingsPage'
import SidebarActionButton from './SidebarActionButton/SidebarActionButton'
import routes, {ROUTE_TITLE_MAP} from '../../routes'

const {Content, Header} = Layout
const {Title} = Typography

function DashboardPage() {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
    const location = useLocation()

    function handleToggle(collpased) {
        setSidebarCollapsed(collpased)
    }

    return (
        <Layout className={styles.layout}>
            <Sidebar collapsed={sidebarCollapsed} onToggle={handleToggle} />
            <Layout>
                <Header className={styles.header}>
                    <SidebarActionButton collapsed={sidebarCollapsed} onToggle={handleToggle} />
                    <Title className={styles.title} level={3}>
                        {ROUTE_TITLE_MAP[location.pathname]}
                    </Title>
                </Header>
                <Content className={styles.content}>
                    <Switch>
                        <Route path={routes.settings} component={SettingsPage} />
                        <Redirect from={routes.dashboard} to={routes.settings} />
                    </Switch>
                </Content>
            </Layout>
        </Layout>
    )
}

export default DashboardPage
