import {React, useState} from 'react'
import {Row, Col, Spin, Modal, Button} from 'antd'
import styles from '../ConfirmationForm/ConfirmationForm.module.css'
import Page from '../../Common/Page'
import TermsOfService from '../AccountCreationForm/TermsOfService'

function OrderSummaryComponent(props) {
    const [showTos, setShowTos] = useState(false)

    function toggleTos(e) {
        e.preventDefault() //Prevent to toggle TOS checkbox
        setShowTos(!showTos)
    }

    if (!props.loading) {
        return (
            <>
                <Row>
                    <Col xs={24} xl={24}>
                        <div className={styles.order_summary_container}>
                            <div className={styles.order_summary_card}>
                                <div className={styles.order_summary_card_title}>
                                    <h3
                                        style={{
                                            color: 'white',
                                            marginBottom: '0px',
                                            fontSize: '16px'
                                        }}
                                    >
                                        <strong>ORDER SUMMARY</strong>
                                    </h3>
                                </div>
                                <div align="center">
                                    <div
                                        className={styles.order_summary_card_title}
                                        style={{
                                            backgroundColor: '#c9fdd7',
                                            height: '80px',
                                            marginTop: '0px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginBottom: '0px'
                                        }}
                                    >
                                        <div>
                                            <h3
                                                style={{
                                                    marginBottom: '0px',
                                                    color: 'grey',
                                                    fontWeight: 'bold',
                                                    fontSize: '18px'
                                                }}
                                            >
                                                Confirmation code
                                            </h3>
                                        </div>
                                        <div>
                                            <h3
                                                style={{
                                                    marginBottom: '0px',
                                                    color: 'grey',
                                                    fontSize: '16px'
                                                }}
                                            >
                                                {props.dataConf?.confirmationCode &&
                                                    props.dataConf.confirmationCode}
                                            </h3>
                                        </div>
                                    </div>

                                    <h3
                                        style={{
                                            marginTop: '20px',
                                            marginBottom: '0px',
                                            color: 'grey',
                                            fontSize: '18px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Plan details
                                    </h3>
                                    <li style={{color: 'grey'}}>$0.10 per kWh discount</li>
                                    <li style={{color: 'grey'}}>100% renewable energy</li>
                                    <li style={{color: 'grey'}}>Same rate for 12 months</li>
                                    <h3
                                        style={{
                                            marginTop: '30px',
                                            marginBottom: '0px',
                                            color: 'grey',
                                            fontSize: '18px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Service address
                                    </h3>
                                    <h4
                                        style={{
                                            marginBottom: '0px',
                                            color: 'grey'
                                        }}
                                    >
                                        {props.dataConf?.fullAddress && props.dataConf.fullAddress}
                                    </h4>
                                    <h3
                                        style={{
                                            marginTop: '30px',
                                            marginBottom: '0px',
                                            color: 'grey',
                                            fontSize: '18px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Utility
                                    </h3>
                                    <h4
                                        style={{
                                            marginBottom: '0px',
                                            color: 'grey'
                                        }}
                                    >
                                        {props.dataConf?.utility && props.dataConf.utility}
                                    </h4>

                                    <Modal
                                        visible={showTos}
                                        width={1000}
                                        onCancel={toggleTos}
                                        footer={[
                                            <Button key="back" type="primary" onClick={toggleTos}>
                                                Close
                                            </Button>
                                        ]}
                                        centered
                                    >
                                        <TermsOfService />
                                    </Modal>
                                    <h4
                                        style={{
                                            marginBottom: '0px',
                                            fontWeight: 'bold',
                                            color: '#00b906',
                                            marginTop: '50px'
                                        }}
                                    >
                                        <a href="/#" onClick={toggleTos}>
                                            &nbsp;Terms of Service&nbsp;
                                        </a>{' '}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <Page>
                <div className="spinload" align="center">
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Spin tip="Loading Order Summary..." />
                </div>
            </Page>
        )
    }
}

export default OrderSummaryComponent
