import {Modal} from 'antd'
import {useState} from 'react'
import SendCodeFirstStep from './SendCodeFirstStep'
import styles from './SendCodeModal.module.css'
import SendCodeSecondStep from './SendCodeSecondStep'

function SendCodeModal({onCancel, ...props}) {
    const [sendCodeClicked, setSendCodeClicked] = useState(false)
    const [address, setAddress] = useState('')

    function onCancelClick() {
        onCancel()
        setAddress('')
        setSendCodeClicked(false)
    }

    function onSendCodeDone(codeHasBeenSent, forgotPasswordAddress) {
        setAddress(forgotPasswordAddress)
        setSendCodeClicked(codeHasBeenSent)
    }

    return (
        <Modal onCancel={onCancelClick} className={styles.sendCodeModal} {...props}>
            {!sendCodeClicked ? (
                <SendCodeFirstStep onCancel={onCancelClick} onSendCodeDone={onSendCodeDone} />
            ) : (
                <SendCodeSecondStep onCancel={onCancelClick} forgotPasswordAddress={address} />
            )}
        </Modal>
    )
}

export default SendCodeModal
