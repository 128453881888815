import React from 'react'
import './Footer.responsive.css'

const FooterComponent = () => (
    <>
        <div className={'footer_box'}>
            <div className={'footer_text'}>
                <h4 style={{color: 'grey', marginBottom: '0px'}}>
                    COPYRIGHT 2021, SAVESOLAR CORPORATION, ALL RIGHT RESERVED.
                </h4>
            </div>
            <div className={'footer_text_links'}>
                <a style={{color: 'grey', fontWeight: 'normal'}} href="https://www.savesolar.us/">
                    HOME
                </a>{' '}
                <a
                    style={{color: 'grey', fontWeight: 'normal', wordSpacing: 'normal'}}
                    href="https://www.savesolar.us/about-us/"
                >
                    ABOUT US
                </a>{' '}
                <a
                    style={{color: 'grey', fontWeight: 'normal', wordSpacing: 'normal'}}
                    href="https://www.savesolar.us/contact-us/"
                >
                    CONTACT US
                </a>{' '}
                <a
                    style={{color: 'grey', fontWeight: 'normal', wordSpacing: 'normal'}}
                    href="https://www.savesolar.us/privacy-policy/"
                >
                    PRIVACY POLICY
                </a>{' '}
                <a
                    style={{color: 'grey', fontWeight: 'normal', wordSpacing: 'normal'}}
                    href="https://www.savesolar.us/terms-of-use/"
                >
                    TERMS OF USE
                </a>
            </div>
        </div>
    </>
)

export default FooterComponent
