import {React} from 'react'
import {useQuery} from '@apollo/client'
import {Row, Col} from 'antd'
import styles from './Common.module.css'
import './Common.responsive.css'
import {GET_FACILITY_SHOW_LOCAL_FARM} from '../../../graphQL/queries/facility'
import {facilityUrlEndpoint} from '../../config/index'

function SpotsRemainingComponent() {
    const {data} = useQuery(GET_FACILITY_SHOW_LOCAL_FARM, {
        fetchPolicy: 'network-only'
    })

    const localFarm = data?.facilityShowLocalFarm[0] || {}

    return (
        <>
            <Row>
                <Col xs={24} xl={24}>
                    <div className={'spots_remaining_card_container'}>
                        <div className={styles.spots_remaining_card}>
                            <div className={styles.spots_remaining_card_title}>
                                <h3
                                    align="center"
                                    style={{color: 'white', marginBottom: '15px', fontSize: '18px'}}
                                >
                                    <strong>Your Local Solar Farm</strong>
                                    <span style={{whiteSpace: 'nowrap'}}>
                                        {localFarm.siteName ? ` (${localFarm.siteName})` : ''}
                                    </span>
                                </h3>
                            </div>
                            <div align="center">
                                {localFarm.facilityImageUrl ? (
                                    <img
                                        className={styles.facility_photo}
                                        alt="Facility"
                                        src={`${facilityUrlEndpoint}/${localFarm.facilityImageUrl}`}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                            <h4 style={{marginBottom: '0px', marginTop: '20px'}}>
                                <b>Location:</b> Washington D.C.
                            </h4>
                            <h4 style={{marginBottom: '0px'}}>
                                <b>Project Size:</b>{' '}
                                {localFarm.nameplateCapacityAc
                                    ? localFarm.nameplateCapacityAc < 1000
                                        ? `${(data?.facilityShowLocalFarm[0].nameplateCapacityAc).toFixed(
                                              1
                                          )} kW`
                                        : `${(
                                              data?.facilityShowLocalFarm[0].nameplateCapacityAc /
                                              1000
                                          ).toFixed(1)} MW`
                                    : ''}{' '}
                            </h4>
                            <h4 style={{marginBottom: '0px'}}>
                                <b>Clean Energy Generated:</b> {localFarm.anticipatedAnnualGenAc}{' '}
                                kWh / year
                            </h4>
                            <h4 style={{marginBottom: '0px'}}>
                                <b>Emissions Prevented:</b>{' '}
                                {localFarm.anticipatedAnnualGenAc
                                    ? localFarm.anticipatedAnnualGenAc * 0.85
                                    : ''}{' '}
                                lbs CO2 / year
                            </h4>
                            <hr
                                className={'hr_header'}
                                style={{marginTop: '20px', marginBottom: '15px'}}
                            ></hr>
                            <div className={styles.spots_highlight_box}>
                                <h2 className={styles.spots_remaining_text}>Spots Remaining</h2>
                                <div className={styles.spots_blue_highlight} />
                            </div>
                            <h2
                                style={{
                                    fontSize: '70px',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'center',
                                    color: '#ffa952',
                                    marginTop: '-15px'
                                }}
                            >
                                18
                            </h2>
                        </div>
                    </div>
                </Col>
            </Row>
            <div>
                <br />
            </div>
            <Row>
                <Col xs={24} xl={24}>
                    <div className={styles.estimated_savings_container}>
                        <div className={styles.estimated_savings_card}>
                            <div className={styles.estimated_savings_card_title}>
                                <h3
                                    style={{
                                        color: '#757a79',
                                        marginBottom: '-5px',
                                        fontSize: '18px'
                                    }}
                                >
                                    <strong>Estimated Savings</strong>
                                </h3>
                            </div>
                            <h4
                                style={{
                                    marginBottom: '0px',
                                    marginTop: '25px',
                                    marginLeft: '50px'
                                }}
                            >
                                <b>Original Bill:</b> $250
                            </h4>
                            <h4 style={{marginBottom: '0px', marginLeft: '50px'}}>
                                <b>Credit:</b> $25
                            </h4>
                            <h4 style={{marginBottom: '0px', marginLeft: '50px'}}>
                                <b>New Total:</b>
                                <span
                                    style={{
                                        color: '#00b906',
                                        marginLeft: '15px',
                                        fontSize: '35px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    $225
                                </span>
                            </h4>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default SpotsRemainingComponent
