import React from 'react'
import {Divider} from 'antd'
import BillModalUploadFiles from '../BillModalUploadFiles/BillModalUploadFiles'

import styles from './BillModalStep2.module.css'

import Alert from './warning.svg'

const BillModalStep2 = ({onConfirm}) => {
    return (
        <>
            <Divider className={styles.divider} />

            <div className={styles.icon_text} data-testid="uploadBill-upload-container">
                <img alt="" src={Alert} />
                <h3 className={styles.section}>
                    Ensure that <strong> all pages </strong> of the bill are uploaded and the
                    corners and text are clearly visible. The valid formats are .jpeg, .jpg, .png, .pdf, .heic.
                    You can upload a maximum of 10 files with a total size of 4MB.
                </h3>
            </div>
            <div className={styles.upload_container}>
                <BillModalUploadFiles onConfirm={onConfirm} allowedExtensions={".jpeg|.jpg|.png|.pdf|.heic"} fileMaxLength={4000000} maxNumberFiles={10}/>
            </div>
        </>
    )
}

export default BillModalStep2
