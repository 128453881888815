import React from 'react'
import {Button, Divider} from 'antd'
import CameraIcon from './CameraIcon'
import ChatBubbleIcon from './ChatBubbleIcon'
import EmailIcon from './MailIcon'

import styles from './BillModalStep1.module.css'

const BillModalStep1 = ({onUpload}) => {
    const openChatBubble = () => {
        window.HubSpotConversations.widget.open()
    }
    return (
        <>
            <Divider className={styles.divider} />
            <h3 className={styles.section}>Take a photo or upload your utility bill</h3>
            <Button
                type="primary"
                data-testid="uploadBill-upload-button"
                size="large"
                className={styles.upload_button}
                icon={<CameraIcon />}
                onClick={onUpload}
            >
                Upload your bill
            </Button>
            <Divider className={styles.divider} />
            <h3 className={styles.section}>Chat live with our support agent</h3>
            <Button
                type="primary"
                size="large"
                className={styles.chat_button}
                icon={<ChatBubbleIcon />}
                onClick={openChatBubble}
            >
                Chat with us
            </Button>
            <Divider className={styles.divider} />
            <h3 className={styles.section}>Or e-mail our support team for same day response</h3>
            <Button
                type="primary"
                size="large"
                className={styles.email_button}
                icon={<EmailIcon />}
            >
                <a href="mailto:support@savesolar.us">E-mail us</a>
            </Button>
        </>
    )
}

export default BillModalStep1
