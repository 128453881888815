import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App/App'

import {ApolloProvider} from '@apollo/client'
import client from './graphQL/setup'

import './index.less'

ReactDOM.render(
    <ApolloProvider client={client}>
        <App client={client} />
    </ApolloProvider>,
    document.getElementById('root')
)
