import styles from './RoundButton.module.css'
import {Button} from 'antd'
import classnames from 'classnames'

function RoundButton({children, className, ...props}) {
    return (
        <Button className={classnames(className, styles.roundButton)} {...props}>
            {children}
        </Button>
    )
}

export default RoundButton
