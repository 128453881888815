import {gql} from '@apollo/client'

export const GET_SUBSCRIBER = gql`
    query FetchSubscriber($subscriberId: String!) {
        subscriber(subscriberId: $subscriberId) {
            firstName
            lastName
            phoneNumber
            secondaryPhoneNumber
            email
            fullAddress
            utility
            utilityAccountNumber
            utilityServiceNumber
        }
    }
`

export const GET_ALL_SUBSCRIBERS = gql`
    query FetchSubscribers {
        subscribers {
            subscriberId
            firstName
            lastName
            phoneNumber
            secondaryPhoneNumber
            email
            utility
            utilityAccountNumber
            utilityServiceNumber
            joinedWaitlist
            allocated
            hubspotContactId
            authConfigured
            projectId
            contactMethodId
            fullAddress
            subscriberUsageForecasts {
                usageKwh
            }
        }
        projects {
            projectId
            project
        }
        contactMethods {
            contactMethodId
            contactMethod
        }
    }
`

export const GET_SUBSCRIBER_BY_EMAIL = gql`
    query FetchSubscriberByEmail($email: String!) {
        subscriber(email: $email) {
            subscriberId
            firstName
            lastName
            phoneNumber
            secondaryPhoneNumber
            email
            fullAddress
            utility
            utilityAccountNumber
            utilityServiceNumber
        }
    }
`

export const GET_UTILITY_BY_ZIPCODE = gql`
    query utility($zipcode: String!) {
        utility(zipcode: $zipcode) {
            utilityZipcode
            utilityName
        }
    }
`
