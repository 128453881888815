import {Typography, Form, InputNumber, Select, Button} from 'antd'
import styles from './EnergyProfileForm.module.css'

const {Title} = Typography
const {Option} = Select

function EnergyProfileForm() {
    return (
        <div className={styles.energyProfile}>
            <Title level={5}>Complete your Energy Profile</Title>
            <Form layout="vertical" initialValues={{homeType: 'apartment', heatingType: 'natural'}}>
                <Form.Item label="Home Type" name="homeType">
                    <Select>
                        <Option value="apartment">Apartment/Condo</Option>
                        <Option value="single">Single Family (House, Town House, Row House)</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Heating Type" name="heatingType">
                    <Select>
                        <Option value="natural">Natural Gas</Option>
                        <Option value="electric">Electric</Option>
                        <Option value="none">None</Option>
                        <Option value="other">Other</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="numResidents" label="Number of residents">
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item name="homeSize" label="Home Size (sqft)">
                    <InputNumber />
                </Form.Item>
                <Button type="primary" block htmlType="submit">
                    Save
                </Button>
            </Form>
        </div>
    )
}

export default EnergyProfileForm
