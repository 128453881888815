const routes = {
    dashboard: '/',
    settings: '/settings',
    login: '/login',
    onBoarding: '/onboarding',
    onBoardingSignup: '/onboarding/signup',
    onBoardingUtility: '/onboarding/utility',
    onBoardingEnergyProfile: '/onboarding/energyProfile',
    onBoardingPayment: '/onboarding/payment',
    onBoardingConfirmation: '/onboarding/confirmation',
    onBoardingWaitlist: '/onboarding/waitlist'
}

export const ROUTE_TITLE_MAP = {
    [routes.settings]: 'Settings'
}

export default routes
