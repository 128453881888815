import styles from './SidebarActionButton.module.css'
import {LeftOutlined, RightOutlined, MenuOutlined} from '@ant-design/icons'
import {Grid} from 'antd'

const {useBreakpoint} = Grid

function ToggleButton({collapsed, ...props}) {
    const screens = useBreakpoint()
    const isMobile = screens.xs || !screens.lg
    return isMobile ? (
        <MenuOutlined />
    ) : collapsed ? (
        <RightOutlined {...props} />
    ) : (
        <LeftOutlined {...props} />
    )
}

function SidebarActionButton({collapsed, onToggle}) {
    function handleClick() {
        onToggle(!collapsed)
    }
    return (
        <div onClick={handleClick} className={styles.container}>
            <ToggleButton style={{fontSize: '16px'}} collapsed={collapsed} />
        </div>
    )
}

export default SidebarActionButton
