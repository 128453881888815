import {apiEndpoint} from './sync-api-utility-endpoint/api-utility-endpoint'
import axios from 'axios'
import {tokenIsSane} from '../auth/token-sanity-check'

export async function leadTrackingPost(params) {
    try {
        const res = await axios.post(apiEndpoint + '/lead-tracking', {
            utmCampaign: params.utmCampaign,
            utmSource: params.utmSource,
            utmMedium: params.utmMedium,
            timeClicked: new Date(),
            zipCode: params.zipCode
        })

        return res.data.trackingId
    } catch (error) {
        return error
    }
}

export async function callSubscriberGet() {
    let authorizationToken = localStorage.getItem('substkn')
    if (tokenIsSane(authorizationToken) === true) {
        const params = {headers: {Authorization: `Bearer ${authorizationToken}`}}
        const res = await axios.get(apiEndpoint + '/subscriber', params)
        return res
    } else {
        throw new Error('Token is not valid')
    }
}

export async function callAuthenticatePost(tempLinkId) {
    const res = await axios.post(apiEndpoint + '/authenticate', {tempLinkId})
    return res
}

export async function callSignUpPut(bodyReq) {
    let authorizationToken = localStorage.getItem('substkn')
    if (tokenIsSane(authorizationToken) === true) {
        const {data} = await axios.put(
            apiEndpoint + '/sign-up',
            {
                email: bodyReq.email,
                firstName: bodyReq.firstName,
                lastName: bodyReq.lastName,
                phoneNumber: bodyReq.phoneNumber,
                subscriberStatus: bodyReq.subscriberStatus
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + authorizationToken
                }
            }
        )
        return data
    } else {
        throw new Error('Token is not valid')
    }
}

export async function callValidateZipGet(utilityZipcode) {
    try {
        const {data} = await axios.get(apiEndpoint + '/validate-zip/' + utilityZipcode, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return data
    } catch (error) {
        return error
    }
}

export async function callChargebeeHostedpage() {
    let token = localStorage.getItem('substkn')
    if (tokenIsSane(token) === true) {
        const params = {headers: {Authorization: `Bearer ${token}`}}
        const res = await axios.get(apiEndpoint + '/hostedpage', params)
        return res.data
    } else {
        throw new Error('Token is not valid')
    }
}

export async function callUploadSubscriberBills(fileArray) {
    let token = localStorage.getItem('substkn')
    if (tokenIsSane(token) === true) {
        const formData = new FormData()
        fileArray.forEach((v, i) => {
            return formData.append(v.name.substr(0, v.name.lastIndexOf('.')), v)
        })
        const headers = {
            headers: {Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data'}
        }
        const res = await axios.post(`${apiEndpoint}/subscriber-bills`, formData, headers)
        return res
    } else {
        throw new Error('Token is not valid')
    }
}

export async function callPaymentKeyGet() {
    let authorizationToken = localStorage.getItem('substkn')
    if (tokenIsSane(authorizationToken) === true) {
        const {data} = await axios.get(apiEndpoint + '/payment-key', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authorizationToken
            }
        })
        return data
    } else {
        throw new Error('Token is not valid')
    }
}

export async function callStripeBankAccountPost(bankAccountData, stripeToken) {
    const params = new URLSearchParams()

    Object.keys(bankAccountData).forEach((key) => {
        params.append(`bank_account[${key}]`, bankAccountData[key].toString())
    })

    const {data} = await axios.post('https://api.stripe.com/v1/tokens', params.toString(), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${stripeToken}`
        }
    })

    return data
}

export async function callStripeCardPost(cardData, stripeToken) {
    const params = new URLSearchParams()

    Object.keys(cardData).forEach((key) => {
        params.append(`card[${key}]`, cardData[key].toString())
    })

    const {data} = await axios.post('https://api.stripe.com/v1/tokens', params.toString(), {
        headers: {
            Authorization: 'Bearer ' + stripeToken,
            'Content-type': 'application/x-www-form-urlencoded'
        }
    })

    return data
}

export async function callPaymentSourcePost(stripeTempToken, type) {
    let authorizationToken = localStorage.getItem('substkn')
    if (tokenIsSane(authorizationToken) === true) {
        const {data} = await axios.post(
            apiEndpoint + '/payment-source',
            {
                tmpToken: stripeTempToken,
                type: type
            },
            {
                headers: {
                    Authorization: 'Bearer ' + authorizationToken
                }
            }
        )
        return data
    } else {
        throw new Error('Token is not valid')
    }
}
